import * as React from 'react';
import {useForm} from "react-hook-form";
import {Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import BudgetRequestDto from "../../dto/BudgetRequestDto";
import {useTranslation} from "react-i18next";
import ContentItem from "../content-item/ContentItem";

interface BudgetFormProps {
    onSaveBudget: any
}

function BudgetForm({onSaveBudget}: BudgetFormProps) {
    const {t} = useTranslation();
    const {register, handleSubmit, formState: {errors}} = useForm();

    const onSubmit = (data: any) => {
        const budgetData = data as BudgetRequestDto;
        onSaveBudget(budgetData);
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Stack spacing={2}>
                    <ContentItem>
                        <TextField
                            fullWidth
                            defaultValue="" {...register("name", {required: true})}
                            label={t('Name')}
                            helperText={errors.name && t('Name is required')}
                            multiline
                        />
                    </ContentItem>
                    <ContentItem>
                        <Button variant="contained" type="submit">{t('Create')}</Button>
                    </ContentItem>
                </Stack>
            </Box>
        </form>
    );
};

export default BudgetForm;