import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PageableDto from "../../dto/PageableDto";
import {InputAdornment, TableHead, TextField} from "@mui/material";
import RealExpenseDto from "../../dto/RealExpenseDto";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import RealExpenseEditDialog from "../real-expense-edit-dialog/RealExpenseEditDialog";
import {defaultPage, defaultPageSize, rowsPerPageOptions} from "../../constants/pagination";
import {formatDateTimeString} from "../../utils/date.utils";
import RealExpenseDelete from "../real-expense-delete/RealExpenseDelete";
import PlannedExpenseDropdownDto from "../../dto/PlannedExpenseDropdownDto";
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {getCookieTime} from "../../utils/cookie.utils";
import Cookies from "universal-cookie";
import Box from "@mui/material/Box";
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from "@mui/material/IconButton";
import {useTranslation} from "react-i18next";
import ExpenseCategoryRequestDto from "../../dto/ExpenseCategoryRequestDto";

interface Props {
    pageable: PageableDto<RealExpenseDto[]>;
    plannedExpenses: PlannedExpenseDropdownDto[];
    categories: ExpenseCategoryDto[];
    query: string;
    page: number;
    pageSize: number;
    setPage: any;
    setPageSize: any;
    loadData: any;
    onSaveExpenseCategory: (request: ExpenseCategoryRequestDto) => void;
    onChangeQuery: any;
}

export default function RealExpenseList(props: Props) {
    const {t} = useTranslation();
    const {
        pageable,
        query,
        page,
        pageSize,
        setPage,
        setPageSize,
        onChangeQuery,
        loadData,
        onSaveExpenseCategory,
        categories,
        plannedExpenses
    } = props;
    const cookies = new Cookies();

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        loadData(newPage, pageSize, '');
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPageSize(newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
        setPage(0);
        loadData(0, newPageSize, '');
    };

    const onClearQuery = () => {
        var simulatedEvent = {
            target: {
                value: ''
            }
        };
        onChangeQuery(simulatedEvent);
    };

    return (
        <div>
            {pageable?.content
                ? <div>
                    <Box sx={{paddingTop: '1%', paddingBottom: '1%'}}>
                        <TextField fullWidth
                                   id="search-query"
                                   label={t('Search')}
                                   variant="outlined"
                                   value={query}
                                   onChange={onChangeQuery}
                                   InputProps={{
                                       endAdornment: <InputAdornment position="end"><IconButton
                                           aria-label={`${t('Clear search value')}`}
                                           onClick={onClearQuery}
                                           edge="end"
                                           disabled={query.length === 0}
                                       >
                                           <ClearIcon/>
                                       </IconButton></InputAdornment>,
                                   }}
                        />
                    </Box>
                    <TableContainer component={Paper}>
                        <Table sx={{minWidth: 200}} aria-label="Real expenses table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{t('Description')}</TableCell>
                                    <TableCell>{t('Amount')}</TableCell>
                                    <TableCell>{t('Category')}</TableCell>
                                    <TableCell>{t('Planned')}</TableCell>
                                    <TableCell>{t('Paid at')}</TableCell>
                                    <TableCell>{t('Actions')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {pageable.content.map((row) => (
                                    <TableRow key={`real-expense-${row.businessKey}`}>
                                        <TableCell style={{width: 100}} align="left">
                                            {row.description}
                                        </TableCell>
                                        <TableCell style={{width: 10}} align="left">
                                            {row.amount} zł.
                                        </TableCell>
                                        <TableCell style={{width: 20}} align="left">
                                            {row.category.name}
                                        </TableCell>
                                        <TableCell style={{width: 10}} align="left">
                                            {row.plannedExpenseBusinessKey ? <RadioButtonCheckedIcon/> :
                                                <RadioButtonUncheckedIcon/>}
                                        </TableCell>
                                        <TableCell style={{width: 50}} align="left">
                                            {formatDateTimeString(row.paidAt)}
                                        </TableCell>
                                        <TableCell style={{width: 50}} align="left">
                                            <RealExpenseEditDialog realExpense={row}
                                                                   plannedExpenses={plannedExpenses}
                                                                   categories={categories}
                                                                   onSave={loadData}
                                                                   onSaveExpenseCategory={onSaveExpenseCategory}
                                            />
                                            <RealExpenseDelete row={row}
                                                               onDeleted={() => loadData(defaultPage, defaultPageSize, '')}/>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={rowsPerPageOptions}
                                        colSpan={3}
                                        count={pageable.totalElements}
                                        rowsPerPage={pageSize}
                                        page={page}
                                        SelectProps={{
                                            inputProps: {
                                                'aria-label': `${t('rows per page')}`,
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer></div> : <p>{t('No data')}</p>}
        </div>
    );
}