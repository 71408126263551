import * as React from 'react';
import LoginForm from "../../components/login-form/LoginForm";

function Login() {
    return (
        <div>
            <LoginForm/>
        </div>
    );
};

export default Login;