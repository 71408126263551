import PlannedExpenseDto from "../../dto/PlannedExpenseDto";
import * as React from "react";
import {useEffect, useState} from "react";
import RealExpenseDto from "../../dto/RealExpenseDto";
import {getRealExpensesByPlannedExpense, markPlannedPaid} from "../../api/api";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {formatDateTimeString} from "../../utils/date.utils";
import PlannedExpenseRepeatDialog from "../planned-expense-repeat-dialog/PlannedExpenseRepeatDialog";
import PlannedExpenseEditDialog from "../planned-expense-edit-dialog/PlannedExpenseEditDialog";
import PlannedExpenseDelete from "../planned-expense-delete/PlannedExpenseDelete";
import {Collapse, TableHead} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {getBalanceTextStyle} from "../../utils/balance.utils";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import RealExpenseDelete from "../real-expense-delete/RealExpenseDelete";
import {useTranslation} from "react-i18next";
import MarkPaidRequestDto from "../../dto/MarkPaidRequestDto";
import {Link} from "react-router-dom";

interface Props {
    row: PlannedExpenseDto
    onChange: () => void
}

export default function PlannedExpenseListRow(props: Props) {
    const {t} = useTranslation();
    const {
        row,
        onChange
    } = props;
    const [open, setOpen] = useState(false);
    const [realExpenses, setRealExpenses] = useState<RealExpenseDto[]>([]);

    const getExpenses = (businessKey: string) => {
        getRealExpensesByPlannedExpense(businessKey).then(result => {
            setRealExpenses(result);
        });
    };

    const markPaid = (businessKey: string, state: boolean) => {
        const payload = {
            paid: state
        } as MarkPaidRequestDto;
        markPlannedPaid(businessKey, payload).then(() => {
            setOpen(!state);
            onChange();
        });
    };

    const onDeleted = () => {
        onChange();
        console.log("changed");
    }

    useEffect(() => {
        if (open) {
            getExpenses(row.businessKey);
        }
    }, [open]);

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {row.description}
                </TableCell>
                <TableCell>
                    {`${row.category?.name}`}
                </TableCell>
                <TableCell>
                    {row.amount} zł. / {row.balance} zł. {row.paid ? "(Paid)" : null}
                </TableCell>
                <TableCell>
                    {formatDateTimeString(row.toPayAt)}
                </TableCell>
                <TableCell>
                    <PlannedExpenseRepeatDialog plannedExpense={row} onSave={onChange}/>
                    <PlannedExpenseEditDialog plannedExpense={row} onSave={onChange}/>
                    <PlannedExpenseDelete row={row} onDeleted={onChange}/>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            {realExpenses ? <div>
                                    <Typography variant="h6" gutterBottom component="div"
                                                style={getBalanceTextStyle(row.balance)}>
                                        {t('Balance')}: {row.balance} zł
                                    </Typography>
                                    <Button
                                        variant="outlined"
                                        component={Link}
                                        to={`/create-expense?plannedExpenseId=${row.businessKey}`}
                                    >
                                        {t('Create new expense')}
                                    </Button>
                                    <Button
                                        onClick={() => markPaid(row.businessKey, !row.paid)}
                                        variant="outlined"
                                    >{row.paid ? t('Mark as unpaid') : t('Mark as paid')}</Button>
                                </div>
                                : null
                            }
                            <Typography variant="h6" gutterBottom component="div">
                                {t('Real expenses history')}
                            </Typography>
                            {realExpenses.length > 0 ? <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('Description')}</TableCell>
                                        <TableCell>{t('Amount')}</TableCell>
                                        <TableCell>{t('Paid at')}</TableCell>
                                        <TableCell>{t('Actions')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {realExpenses?.map((expenseRow) => (
                                        <TableRow key={`${expenseRow.businessKey}-${expenseRow.createdAt}`}>
                                            <TableCell component="th" scope="row">
                                                {expenseRow.description}
                                            </TableCell>
                                            <TableCell>{expenseRow.amount} zł.</TableCell>
                                            <TableCell>{expenseRow.paidAt}</TableCell>
                                            <TableCell>
                                                <RealExpenseDelete row={expenseRow} onDeleted={onDeleted}/>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table> : null}
                            {realExpenses.length == 0 ? <Typography>{t('No expenses')}</Typography> : null}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}