import {Outlet} from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import React, {useContext} from "react";
import {AppContext} from "./context/app";
import Login from "./pages/login/Login";
import AppContextProps from "./props/AppContextProps";
import Filters from "./components/filters/Filters";
import AppLoader from "./components/app-loader/AppLoader";
import {Alert} from "@mui/material";
import Redirector from "./components/redirector/Redirector";

function Layout() {
    const {isLoggedIn, isOnline, appLoading} = useContext(AppContext) as AppContextProps;

    return (
        <>
            <Navbar/>
            {!isOnline && !appLoading ?
                <Alert severity="error">Service is OFFLINE! We should come back after a few minutes.</Alert> : null}
            {isOnline && isLoggedIn ? <Filters/> : null}
            {isOnline && isLoggedIn ? <Outlet/> : null}
            {isOnline && !isLoggedIn ? <Login/> : null}
            <AppLoader/>
            <Redirector/>
        </>
    )
};
export default Layout;