import * as React from 'react';
import {useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PageableDto from "../../dto/PageableDto";
import BudgetDto from "../../dto/BudgetDto";
import {TableHead} from "@mui/material";
import {defaultPage, defaultPageSize, rowsPerPageOptions} from "../../constants/pagination";
import {getCookieTime} from "../../utils/cookie.utils";
import Cookies from "universal-cookie";
import {useTranslation} from "react-i18next";

interface Props {
    pageable: PageableDto<BudgetDto[]>;
    loadData: any;
}

export default function BudgetList(props: Props) {
    const {t} = useTranslation();
    const {pageable, loadData} = props;
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const cookies = new Cookies();

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        loadData(newPage, pageSize);
    };
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        loadData(0, newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
    };

    return (
        <div>
            {pageable?.content
                ? <TableContainer component={Paper}>
                    <Table sx={{minWidth: 300}} aria-label={`${t('Budget list')}`}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('Name')}</TableCell>
                                {/*<TableCell>Actions</TableCell>*/}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pageable.content.map((row) => (
                                <TableRow key={`${row.businessKey}-${row.userBusinessKey}`}>
                                    <TableCell style={{width: 100}} align="left">
                                        {row.name}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={rowsPerPageOptions}
                                    colSpan={3}
                                    count={pageable.totalElements}
                                    rowsPerPage={pageSize}
                                    page={page}
                                    SelectProps={{
                                        inputProps: {
                                            'aria-label': `${t('rows per page')}`,
                                        },
                                        native: true,
                                    }}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer> : <p>{t('No data')}</p>}
        </div>
    );
}