import BudgetDto from "../dto/BudgetDto";
import {axiosInstance} from "./axios";
import BudgetRequestDto from "../dto/BudgetRequestDto";
import RevenueRequestDto from "../dto/RevenueRequestDto";
import PageableDto from "../dto/PageableDto";
import RevenueDto from "../dto/RevenueDto";
import RealExpenseRequestDto from "../dto/RealExpenseRequestDto";
import ExpenseCategoryDto from "../dto/ExpenseCategoryDto";
import ExpenseCategoryRequestDto from "../dto/ExpenseCategoryRequestDto";
import PlannedExpenseDropdownDto from "../dto/PlannedExpenseDropdownDto";
import PlannedExpenseRequestDto from "../dto/PlannedExpenseRequestDto";
import BudgetDropdownItemDto from "../dto/BudgetDropdownItemDto";
import RealExpenseDto from "../dto/RealExpenseDto";
import PlannedExpenseDto from "../dto/PlannedExpenseDto";
import ExpenseDescriptionDto from "../dto/ExpenseDescriptionDto";
import BudgetSummaryDto from "../dto/BudgetSummaryDto";
import UserDto from "../dto/UserDto";
import PlannedExpenseUpdateRequestDto from "../dto/PlannedExpenseUpdateRequestDto";
import RealExpenseUpdateRequestDto from "../dto/RealExpenseUpdateRequestDto";
import RevenueUpdateRequestDto from "../dto/RevenueUpdateRequestDto";
import RealExpenseSummaryDto from "../dto/RealExpenseSummaryDto";
import LoginRequestDto from "../dto/LoginRequestDto";
import TokenDto from "../dto/TokenDto";
import RefreshTokenRequestDto from "../dto/RefreshTokenRequestDto";
import UpdatePasswordRequestDto from "../dto/UpdatePasswordRequestDto";
import PlannedExpenseBalanceDto from "../dto/PlannedExpenseBalanceDto";
import UserMessageDto from "../dto/UserMessageDto";
import SearchPlannedExpensesRequestDto from "../dto/SearchPlannedExpensesRequestDto";
import UserProgressDto from "../dto/UserProgressDto";
import MarkPaidRequestDto from "../dto/MarkPaidRequestDto";
import PlanningRequestDto from "../dto/PlanningRequestDto";
import PlanningUpdateRequestDto from "../dto/PlanningUpdateRequestDto";
import PlanningDto from "../dto/PlanningDto";
import RevenueSummaryDto from "../dto/RevenueSummaryDto";
import PlannedExpenseSummaryDto from "../dto/PlannedExpenseSummaryDto";
import PlannedExpenseSuggestionDto from "../dto/PlannedExpenseSuggestionDto";
import SuggestionRequestDto from "../dto/SuggestionRequestDto";

export const userMessageCountUnread = async (): Promise<number> => {
    try {
        const response = await axiosInstance.get(`/api/user/message/count-unread`);
        return response.data;
    } catch (error) {
        console.error(error);
        return 0;
    }
};

export const getUserMessagesPaged = async (page: number, size: number): Promise<PageableDto<UserMessageDto[]>> => {
    try {
        const response = await axiosInstance.get(`/api/user/message?page=${page}&size=${size}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as PageableDto<UserMessageDto[]>;
    }
};

export const userMessageMarkAsRead = async (id: string): Promise<void> => {
    try {
        await axiosInstance.put(`/api/user/message/mark-as-read/${id}`);
    } catch (error) {
        console.error(error);
    }
};

export const postLogin = async (loginRequest: LoginRequestDto): Promise<TokenDto> => {
    try {
        const response = await axiosInstance.post(`/api/user/login`, loginRequest);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as TokenDto;
    }
};

export const postRefreshToken = async (token: string): Promise<TokenDto> => {
    try {
        const refreshTokenRequest = {token} as RefreshTokenRequestDto;
        const response = await axiosInstance.post(`/api/refresh-token`, refreshTokenRequest);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as TokenDto;
    }
};

export const updatePassword = async (request: UpdatePasswordRequestDto): Promise<void> => {
    try {
        await axiosInstance.patch(`/api/user/updatePassword`, request);
    } catch (error) {
        console.error(error);
    }
};

export const getConnectionCheck = async (): Promise<boolean> => {
    try {
        await axiosInstance.get(`/api/connection-check`);
        return true;
    } catch (error: any) {
        return error?.response?.status === 401;
    }
};

export const getUserWhoami = async (): Promise<UserDto | null> => {
    try {
        const response = await axiosInstance.get(`/api/user/whoami`);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getBudgetsDropdownItems = async (): Promise<BudgetDropdownItemDto[]> => {
    try {
        const response = await axiosInstance.get(`/api/budget/my/dropdown`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getBudgetsSummaryByDates = async (
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string
): Promise<BudgetSummaryDto> => {
    try {
        const response = await axiosInstance.get(
            `/api/budget-summary/${budgetBusinessKey}?dateFrom=${dateFrom}&dateTo=${dateTo}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return {
            budgetBalance: "0.00",
            plannedExpensesBalance: "0.00",
            incomeAmount: "0.00",
            plannedExpensesAmount: "0.00",
            unplannedExpensesAmount: "0.00",
            realExpensesAmount: "0.00"
        };
    }
};

export const getBudgetsPaged = async (page: number, size: number): Promise<PageableDto<BudgetDto[]>> => {
    try {
        const response = await axiosInstance.get(`/api/budget/my?page=${page}&size=${size}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as PageableDto<BudgetDto[]>;
    }
};

export const saveBudget = async (budgetData: BudgetRequestDto): Promise<BudgetDto> => {
    try {
        const response = await axiosInstance.post(`/api/budget`, budgetData);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as BudgetDto;
    }
};

export const getRevenues = async (
    budgetBusinessKey: string,
    page: number,
    size: number,
    dateFrom: string,
    dateTo: string
): Promise<PageableDto<RevenueDto[]>> => {
    try {
        const response = await axiosInstance.get(
            `/api/revenue/${budgetBusinessKey}?page=${page}&size=${size}&dateFrom=${dateFrom}&dateTo=${dateTo}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as PageableDto<RevenueDto[]>;
    }
};

export const getRevenuesSummary = async (
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string
): Promise<RevenueSummaryDto> => {
    try {
        const response = await axiosInstance.get(
            `/api/revenue/summary/${budgetBusinessKey}?&dateFrom=${dateFrom}&dateTo=${dateTo}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as RevenueSummaryDto;
    }
};

export const saveRevenue = async (revenueRequestData: RevenueRequestDto): Promise<RevenueRequestDto> => {
    try {
        const response = await axiosInstance.post(`/api/revenue`, revenueRequestData);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as RevenueRequestDto;
    }
};

export const updateRevenue = async (businessKey: string, data: RevenueUpdateRequestDto): Promise<void> => {
    try {
        await axiosInstance.put(`/api/revenue/${businessKey}`, data);
    } catch (error) {
        console.error(error);
    }
};

export const deleteRevenue = async (businessKey: string): Promise<void> => {
    try {
        await axiosInstance.delete(`/api/revenue/${businessKey}`);
    } catch (error) {
        console.error(error);
    }
};

export const getExpenseCategories = async (
    budgetBusinessKey: string
): Promise<ExpenseCategoryDto[]> => {
    try {
        const response = await axiosInstance.get(`/api/expense-category/${budgetBusinessKey}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getExpenseDescriptions = async (budgetBusinessKey: string, q: string): Promise<ExpenseDescriptionDto[]> => {
    try {
        const response = await axiosInstance.get(
            `/api/expense-description/${budgetBusinessKey}?q=${q}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const saveExpenseCategory = async (data: ExpenseCategoryRequestDto): Promise<void> => {
    try {
        await axiosInstance.post(`/api/expense-category`, data);
    } catch (error) {
        console.error(error);
    }
};

export const getPlannedExpensesDropdown = async (
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string
): Promise<PlannedExpenseDropdownDto[]> => {
    try {
        const response = await axiosInstance.get(
            `/api/planned-expense/dropdown/${budgetBusinessKey}?dateFrom=${dateFrom}&dateTo=${dateTo}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const searchPlannedExpensesPaged = async (
    request: SearchPlannedExpensesRequestDto,
    page: number,
    size: number
): Promise<PageableDto<PlannedExpenseDto[]>> => {
    try {
        const response = await axiosInstance.post(
            `/api/planned-expense/search?page=${page}&size=${size}`,
            request
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as PageableDto<PlannedExpenseDto[]>;
    }
};

export const getPlannedExpensesSummary = async (
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string
): Promise<PlannedExpenseSummaryDto> => {
    try {
        const response = await axiosInstance.get(
            `/api/planned-expense/summary/${budgetBusinessKey}?&dateFrom=${dateFrom}&dateTo=${dateTo}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as PlannedExpenseSummaryDto;
    }
};

export const getPlannedExpensesBalance = async (
    businessKey: string
): Promise<PlannedExpenseBalanceDto> => {
    try {
        const response = await axiosInstance.get(
            `/api/planned-expense/${businessKey}/balance`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return {
            balance: "0.00"
        };
    }
};

export const getPlannedExpensesSuggestions = async (
    budgetBusinessKey: string,
    request: SuggestionRequestDto
): Promise<PlannedExpenseSuggestionDto[]> => {
    try {
        const response = await axiosInstance.post(
            `/api/planned-expense/suggestions/${budgetBusinessKey}`
            , request
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const getRealExpensesByPlannedExpense = async (
    businessKey: string
): Promise<RealExpenseDto[]> => {
    try {
        const response = await axiosInstance.get(
            `/api/real-expense/by-planned-expense/${businessKey}`
        );
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
};

export const savePlannedExpense = async (data: PlannedExpenseRequestDto): Promise<void> => {
    console.log(data);
    try {
        axiosInstance.post(`/api/planned-expense`, data);
    } catch (error) {
        console.error(error);
    }
};

export const updatePlannedExpense = async (businessKey: string, data: PlannedExpenseUpdateRequestDto): Promise<void> => {
    try {
        await axiosInstance.put(`/api/planned-expense/${businessKey}`, data);
    } catch (error) {
        console.error(error);
    }
};

export const deletePlannedExpense = async (businessKey: string): Promise<void> => {
    try {
        await axiosInstance.delete(`/api/planned-expense/${businessKey}`);
    } catch (error) {
        console.error(error);
    }
};

export const getRealExpensesPaged = async (
    budgetBusinessKey: string,
    page: number,
    size: number,
    dateFrom: string,
    dateTo: string,
    query: string
): Promise<PageableDto<RealExpenseDto[]>> => {
    try {
        const response = await axiosInstance.get(
            `/api/real-expense/${budgetBusinessKey}?q=${query}&dateFrom=${dateFrom}&dateTo=${dateTo}&page=${page}&size=${size}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as PageableDto<RealExpenseDto[]>;
    }
};

export const getRealExpensesSummary = async (
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string
): Promise<RealExpenseSummaryDto> => {
    try {
        const response = await axiosInstance.get(
            `/api/real-expense/${budgetBusinessKey}/summary?dateFrom=${dateFrom}&dateTo=${dateTo}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return {
            totalAmount: "0.00"
        } as RealExpenseSummaryDto;
    }
};

export const saveRealExpense = async (data: RealExpenseRequestDto): Promise<void> => {
    try {
        await axiosInstance.post(`/api/real-expense`, data);
    } catch (error) {
        console.error(error);
    }
};

export const updateRealExpense = async (businessKey: string, data: RealExpenseUpdateRequestDto): Promise<void> => {
    try {
        await axiosInstance.put(`/api/real-expense/${businessKey}`, data);
    } catch (error) {
        console.error(error);
    }
};

export const deleteRealExpense = async (businessKey: string): Promise<void> => {
    try {
        await axiosInstance.delete(`/api/real-expense/${businessKey}`);
    } catch (error) {
        console.error(error);
    }
};

export const getUserProgress = async (): Promise<UserProgressDto> => {
    try {
        const response = await axiosInstance.get(
            `/api/user/progress`);
        return response.data;
    } catch (error) {
        console.error(error);
        return {
            experience: 0
        } as UserProgressDto;
    }
};

export const markPlannedPaid = async (businessKey: string, data: MarkPaidRequestDto): Promise<void> => {
    try {
        await axiosInstance.put(`/api/planned-expense/mark-paid/${businessKey}`, data);
    } catch (error) {
        console.error(error);
    }
};

export const createPlanning = async (data: PlanningRequestDto): Promise<PlanningDto> => {
    try {
        const response = await axiosInstance.post(`/api/planning`, data);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as PlanningDto;
    }
};

export const updatePlanning = async (id: string, data: PlanningUpdateRequestDto): Promise<void> => {
    try {
        await axiosInstance.put(`/api/planning/${id}`, data);
    } catch (error) {
        console.error(error);
    }
};

export const getPlannings = async (
    budgetBusinessKey: string,
    page: number,
    size: number
): Promise<PageableDto<PlanningDto[]>> => {
    try {
        const response = await axiosInstance.get(
            `/api/planning/${budgetBusinessKey}?page=${page}&size=${size}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as PageableDto<PlanningDto[]>;
    }
};

export const getPlanningById = async (
    id: string,
): Promise<PlanningDto> => {
    try {
        const response = await axiosInstance.get(`/api/planning/by-id/${id}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return {} as PlanningDto;
    }
};
