import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {pl} from "date-fns/locale";
import PlannedExpenseUpdateRequestDto from "../../dto/PlannedExpenseUpdateRequestDto";
import {updatePlannedExpense} from "../../api/api";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {parseISO} from "date-fns";
import PlannedExpenseDto from "../../dto/PlannedExpenseDto";
import {useTranslation} from "react-i18next";
import {formatDateTime} from "../../utils/date.utils";
import FormItem from "../form-item/FormItem";


interface Props {
    plannedExpense: PlannedExpenseDto,
    onSave: any
}

export default function PlannedExpenseEditDialog(props: Props) {
    const {t} = useTranslation();
    const {plannedExpense, onSave} = props;
    const {register, getValues, setValue, formState: {errors}} = useForm();
    const [open, setOpen] = useState(false);

    const parseDate = (date: string) => {
        return parseISO(date);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSubmit = (data: any) => {
        const request = data as PlannedExpenseUpdateRequestDto;
        updatePlannedExpense(plannedExpense.businessKey, request).then(() => {
            setOpen(false);
            onSave();
        });
    };

    const handleDateFieldChange = (fieldName: string, date: any) => {
        setValue(fieldName, formatDateTime(date), {shouldValidate: true});
    };

    const onChangeAmount = (event: any) => {
        setValue("amount", event.target.value.replace(",", "."));
    }

    const initForm = () => {
        handleDateFieldChange("toPayAt", parseDate(plannedExpense.toPayAt));
        setValue("amount", plannedExpense.amount);
        setValue('description', plannedExpense.description);
    };

    useEffect(() => {
        initForm();
    }, []);

    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <EditIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Edit planned expense</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Edit planned expense: {plannedExpense.description}
                    </DialogContentText>
                    <form>
                        <Box sx={{width: '100%'}}>
                            <Stack spacing={2}>
                                <FormItem>
                                    <FormControl fullWidth>
                                        <InputLabel htmlFor="adornment-amount">
                                            Amount
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            type="string"
                                            inputProps={{
                                                inputMode: "decimal",
                                                pattern: "[0-9.,]*",
                                                autoComplete: "off"
                                            }}
                                            defaultValue={plannedExpense.amount}
                                            {...register("amount", {required: true})}
                                            onChange={onChangeAmount}
                                            id="adornment-amount"
                                            endAdornment={<InputAdornment position="end">zł.</InputAdornment>}
                                        />
                                        {errors.amount && <FormHelperText>Amount is required</FormHelperText>}
                                    </FormControl>
                                    <input type="hidden" {...register("description", {required: true})} />
                                    {errors.description &&
                                        <FormHelperText>{t('Description is required')}</FormHelperText>}
                                </FormItem>
                                <FormItem>
                                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
                                        <DateTimePicker
                                            label="To pay at"
                                            defaultValue={parseDate(plannedExpense.toPayAt)}
                                            onChange={(newValue) => handleDateFieldChange('toPayAt', newValue)}
                                        />
                                    </LocalizationProvider>
                                </FormItem>
                            </Stack>
                        </Box>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit" onClick={() => onSubmit(getValues())}>Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};