import * as React from 'react';
import Box from "@mui/material/Box";
import {Accordion, AccordionDetails, AccordionSummary, Stack} from "@mui/material";
import RealExpenseSummaryDto from "../../dto/RealExpenseSummaryDto";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import {getCategoryByBusinessKey} from "../../utils/expenseCategory.utils";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from "react-i18next";
import ContentItem from "../content-item/ContentItem";


interface Props {
    categories: ExpenseCategoryDto[],
    summary: RealExpenseSummaryDto
}

export default function RealExpenseSummaryAccordion(props: Props) {
    const {t} = useTranslation();
    const {categories, summary} = props;

    const loadCategoryNameByBusinessKey = (businessKey: string): string => {
        const category = getCategoryByBusinessKey(categories, businessKey);

        if (category !== null) {
            return category.name;
        }

        return '';
    };

    const getSortedEntries = () => {
        const sortedEntries = Object.entries(summary.balanceByCategoryAmount)
            .sort((a, b) => b[1] - a[1]);

        return sortedEntries;
    }

    return (
        <div>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}>
                    <Typography>{t('Show list')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Stack spacing={2}>
                            <ContentItem>
                                <ul>
                                    {summary.balanceByCategoryAmount
                                        ? getSortedEntries().map(
                                            ([categoryBusinessKey, amount]) => (
                                                <li key={categoryBusinessKey}>
                                                    {loadCategoryNameByBusinessKey(categoryBusinessKey)} -{amount} zł.
                                                </li>
                                            )) : null}
                                </ul>
                            </ContentItem>
                        </Stack>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};