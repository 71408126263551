import * as React from 'react';
import {useContext} from 'react';
import {AppContext} from "../../context/app";
import AppContextProps from "../../props/AppContextProps";
import {useNavigate} from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {Breadcrumbs} from "@mui/material";
import {t} from "i18next";
import RevenueForm from "../../components/revenue-form/RevenueForm";
import {saveRevenue} from "../../api/api";

function CreateFirstRevenue() {
    const {selectedBudget} = useContext(AppContext) as AppContextProps;
    const navigate = useNavigate();
    const handleOnSave = (data: any) => {
        saveRevenue(data).then(() => {
            navigate("/");
        });
    };

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('Create first revenue')}</Typography>
            </Breadcrumbs>
            <h1>Twój pierwszy budżet już jest gotowy!</h1>
            <h2>Utwórz teraz swój pierwszy przychód.</h2>
            <p>Na przykład pensja, zasiłek, pieniądze za sprzedane przedmioty.</p>
            <RevenueForm
                budgetBusinessKey={selectedBudget}
                onSave={handleOnSave}
                showSaveButton={true}
                defaultReceiptAt={new Date()}
            />
        </div>
    );
}

export default CreateFirstRevenue;