export const getBalanceTextStyle = (balance: string) => {
    if (!balance) {
        return {}
    }

    if (parseFloat(balance) === 0) {
        return {color: "blue"};
    }

    if (parseFloat(balance) < 0) {
        return {color: "red"};
    }

    if (parseFloat(balance) > 0) {
        return {color: "green"};
    }
};