import * as React from 'react';
import {useEffect, useState} from 'react';
import Paper from '@mui/material/Paper';
import {Alert, AlertTitle, Stack, styled} from "@mui/material";
import {useTranslation} from "react-i18next";
import PlannedExpenseSuggestionDto from "../../dto/PlannedExpenseSuggestionDto";
import {getPlannedExpensesSuggestions} from "../../api/api";
import PlannedExpenseRepeatDialog from "../planned-expense-repeat-dialog/PlannedExpenseRepeatDialog";


const Item = styled(Paper)(({theme}) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
}));

interface Props {
    budgetBusinessKey: string;
    yearMonth: string;
}

export default function PlannedExpenseSuggestions(props: Props) {
    const {t} = useTranslation();
    const {
        budgetBusinessKey,
        yearMonth
    } = props;
    const [suggestions, setSuggestions] =
        useState<PlannedExpenseSuggestionDto[]>([]);

    const loadData = () => {
        getPlannedExpensesSuggestions(budgetBusinessKey, {yearMonth})
            .then(results => setSuggestions(results));
    };

    const isToRepeat = (suggestion: PlannedExpenseSuggestionDto) => {
        return suggestion.tags.includes("TO_REPEAT");
    }

    const hideSuggestionByExpenseId = (businessKey: string) => {
        const updated = suggestions.filter(
            element => element.plannedExpense.businessKey !== businessKey
        );
        setSuggestions(updated);
    };

    useEffect(() => {
        loadData();
    }, [yearMonth]);

    return (
        <div>
            {suggestions.length > 0
                ?
                <div>
                    <Stack spacing={2}>
                        <h2>Suggestions</h2>
                        {suggestions.filter(item => isToRepeat(item))
                            .map((suggestion) => (
                                <Item key={suggestion.plannedExpense.businessKey}>
                                    <Alert severity="info" onClose={() => hideSuggestionByExpenseId(
                                        suggestion.plannedExpense.businessKey
                                    )}>
                                        <AlertTitle>
                                            {t('Expense')} {suggestion.plannedExpense.description} {t('repeats')}
                                        </AlertTitle>
                                        <p>
                                            {t('If you need repeat this expense click')}
                                            <PlannedExpenseRepeatDialog
                                                plannedExpense={suggestion.plannedExpense}
                                                onSave={() => hideSuggestionByExpenseId(
                                                    suggestion.plannedExpense.businessKey
                                                )}
                                            />
                                        </p>

                                    </Alert>
                                </Item>
                            ))}
                    </Stack>
                </div> : null}
        </div>
    );
}