import * as React from 'react';
import {forwardRef, Ref, useEffect, useImperativeHandle, useState} from 'react';
import {useForm} from "react-hook-form";
import {FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Stack} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import ExpenseCategoryForm from "../expense-category-form/ExpenseCategoryForm";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {pl} from "date-fns/locale";
import PlannedExpenseRequestDto from "../../dto/PlannedExpenseRequestDto";
import ExpenseCategoryAutocomplete from "../expense-category-autocomplete/ExpenseCategoryAutocomplete";
import ExpenseDescriptionAutocomplete from "../expense-description-autocomplete/ExpenseDescriptionAutocomplete";
import {useTranslation} from "react-i18next";
import PlannedExpenseFormMethods from "../../interfaces/PlannedExpenseFormMethods";
import RevenueRequestDto from "../../dto/RevenueRequestDto";
import ExpenseCategoryRequestDto from "../../dto/ExpenseCategoryRequestDto";
import {formatDateTime} from "../../utils/date.utils";
import FormItem from "../form-item/FormItem";


interface Props {
    budgetBusinessKey: string,
    expenseCategories: ExpenseCategoryDto[],
    onSave: any
    onSaveExpenseCategory: (request: ExpenseCategoryRequestDto) => void,
    showSaveButton: boolean
    defaultToPayAt: Date
}

const PlannedExpenseForm = forwardRef((props: Props, ref: Ref<PlannedExpenseFormMethods>) => {
    const {t} = useTranslation();
    const {
        budgetBusinessKey,
        expenseCategories,
        onSave,
        onSaveExpenseCategory,
        showSaveButton,
        defaultToPayAt
    } = props;
    const {
        register,
        handleSubmit,
        setValue,
        formState: {errors},
        getValues
    } = useForm();
    const [description, setDescription] = useState('');

    const onSelectDescription = (event: any, value: string) => {
        setDescription(value);
        setValue('description', value);
    };

    const onSubmit = (data: any) => {
        const plannedExpenseRequest = data as PlannedExpenseRequestDto;
        onSave(plannedExpenseRequest);
    };

    const onSelectCategory = (event: any, value: any) => {
        setValue('categoryBusinessKey', value?.businessKey);
    };

    const handleDateFieldChange = (fieldName: string, date: any) => {
        setValue(fieldName, formatDateTime(date), {shouldValidate: true});
    };

    const onChangeAmount = (event: any) => {
        setValue("amount", event.target.value.replace(",", "."));
    }

    const onSubmitVoid = () => {
        const data = getValues() as RevenueRequestDto;
        onSave(data);
    };

    useImperativeHandle(ref, () => ({
        onSubmitVoid,
    }));

    useEffect(() => {
        setValue("budgetBusinessKey", budgetBusinessKey);
        if (defaultToPayAt) {
            handleDateFieldChange("toPayAt", defaultToPayAt);
        }
    }, [budgetBusinessKey]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Stack spacing={2}>
                    <FormItem>
                        <ExpenseCategoryAutocomplete
                            expenseCategories={expenseCategories}
                            onSelect={onSelectCategory}
                        />
                        <input type="hidden" {...register("categoryBusinessKey", {required: true})} />
                    </FormItem>
                    <FormItem>
                        <ExpenseCategoryForm budgetBusinessKey={budgetBusinessKey} onSave={onSaveExpenseCategory}/>
                    </FormItem>
                    <FormItem>
                        <ExpenseDescriptionAutocomplete value={description} onSelect={onSelectDescription}/>
                        <input type="hidden" {...register("description", {required: true})} />
                        {errors.description && <FormHelperText>{t('Description is required')}</FormHelperText>}
                    </FormItem>
                    <FormItem>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="adornment-amount">
                                {t('Amount')}
                            </InputLabel>
                            <OutlinedInput
                                fullWidth
                                type="string"
                                defaultValue=""
                                inputProps={{
                                    inputMode: "decimal",
                                    pattern: "[0-9.,]*",
                                    autoComplete: "off"
                                }}
                                {...register("amount", {required: true})}
                                onChange={onChangeAmount}
                                id="adornment-amount"
                                endAdornment={<InputAdornment position="end">zł.</InputAdornment>}
                            />
                            {errors.amount && <FormHelperText>{t('Amount is required')}</FormHelperText>}
                        </FormControl>
                    </FormItem>
                    <FormItem>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
                            <DateTimePicker
                                label={t('To pay at')}
                                defaultValue={defaultToPayAt}
                                onChange={(newValue) => handleDateFieldChange('toPayAt', newValue)}
                            />
                        </LocalizationProvider>
                    </FormItem>
                    {showSaveButton ? <FormItem>
                        <Button variant="contained" type="submit">{t('Create')}</Button>
                    </FormItem> : null}
                </Stack>
            </Box>
        </form>
    );
});

export default PlannedExpenseForm;