import * as React from 'react';
import {deletePlannedExpense} from "../../api/api";
import IconButton from "@mui/material/IconButton";
import PlannedExpenseDto from "../../dto/PlannedExpenseDto";
import DeleteIcon from "@mui/icons-material/Delete";
import {useTranslation} from "react-i18next";

interface Props {
    row: PlannedExpenseDto
    onDeleted: any
}

export default function PlannedExpenseDelete(props: Props) {
    const {t} = useTranslation();
    const {row, onDeleted} = props;

    const getMessage = () => {
        return `${t('Are you sure delete planned expense')}:\n${t('Description')}: ${row?.description}\n
        ${t('Amount')}: ${row?.amount} zł.\n${t('To pay at')}: ${row?.toPayAt}\n?`;
    }

    const deleteById = (businessKey: string) => {
        if (confirm(getMessage())) {
            deletePlannedExpense(businessKey).then(() => {
                onDeleted();
            });
        }
    }

    return (
        <IconButton onClick={() => deleteById(row.businessKey)}>
            <DeleteIcon/>
        </IconButton>
    );
};