import * as React from 'react';
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {Breadcrumbs} from "@mui/material";
import {useTranslation} from "react-i18next";

function SavingsGoal() {
    const {t} = useTranslation();
    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('Savings goals')}</Typography>
            </Breadcrumbs>
            Savings goals here
        </div>
    );
};

export default SavingsGoal;