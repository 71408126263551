import {getUserMessagesPaged} from "../../api/api";
import {useEffect, useState} from "react";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import PageableDto from "../../dto/PageableDto";
import UserMessageDto from "../../dto/UserMessageDto";
import UserMessagesList from "../../components/user-messages-list/UserMessagesList";

export default function UserMessages() {
    const emptyPageable = {
        content: [],
        totalPages: 0,
        totalElements: 0,
        size: 0,
        number: 0
    } as PageableDto<UserMessageDto[]>;
    const [pageable, setPageable] = useState<PageableDto<UserMessageDto[]>>(emptyPageable);

    const loadData = async (page: number, size: number) => {
        await getUserMessagesPaged(page, size).then(response => {
            setPageable(response);
        });
    };

    useEffect(() => {
        loadData(defaultPage, defaultPageSize);
    }, []);

    return (
        <div>
            <UserMessagesList pageable={pageable} loadData={loadData}></UserMessagesList>
        </div>
    );
}