import UserMessageDto from "../../dto/UserMessageDto";
import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {Collapse} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FiberNewIcon from '@mui/icons-material/FiberNew';

interface Props {
    item: UserMessageDto;
    onRead: any;
}

export default function UserMessagesListItem(props: Props) {
    const {item, onRead} = props;
    const [open, setOpen] = React.useState(false);
    const sanitizedMessage = React.createElement(
        'div', {dangerouslySetInnerHTML: {__html: item.message}}
    );
    const handleOpen = (state: boolean) => {
        if (state && !item.readState) {
            onRead(item.id);
            item.readState = true;
        }
        setOpen(state)
    }

    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset', cursor: 'pointer'}}} onClick={() => handleOpen(!open)}>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => handleOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                <TableCell>
                    {!item.readState ? <FiberNewIcon/> : null}
                </TableCell>
                <TableCell component="th" scope="row">
                    {item.subject}
                </TableCell>
                <TableCell>{item.createdAt}</TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{margin: 1}}>
                            <Typography variant="h6" gutterBottom component="div">
                                Message:
                            </Typography>
                            <Typography>
                                {sanitizedMessage}
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}