import React from 'react';
import './App.css';
import {AppProvider} from "./context/app";
import AppRouter from "./AppRouter";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
    },
});


function App() {
    return (
        <ThemeProvider theme={darkTheme}>
            <CssBaseline/>
            <AppProvider>
                <AppRouter/>
            </AppProvider>
        </ThemeProvider>
    );
}

export default App;
