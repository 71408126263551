import * as React from 'react';
import {useContext, useEffect, useState} from 'react';
import {Breadcrumbs, CircularProgress, Grid} from "@mui/material";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import PlanningDto from "../../dto/PlanningDto";
import {getPlanningById} from "../../api/api";
import PlanningMonthStepper from "../../components/planning-month-stepper/PlanningMonthStepper";
import {LoadingItem} from "../../components/loading-item/LoadingItem";
import {AppContext} from "../../context/app";
import AppContextProps from "../../props/AppContextProps";
import ContentItem from "../../components/content-item/ContentItem";


function PlanningMonth() {
    const {t} = useTranslation();
    const {id} = useParams();
    const [planning, setPlanning] = useState<PlanningDto | undefined>();
    const [loading, setLoading] = useState(true);
    const {expenseCategories, onSaveExpenseCategory} = useContext(AppContext) as AppContextProps;

    const loadPlanning = (planningId: string) => {
        setLoading(true);
        getPlanningById(planningId).then((resp) => {
            setPlanning(resp);
            setLoading(false);
        });
    };

    useEffect(() => {
        if (!id) {
            return;
        }
        loadPlanning(id);
    }, [id]);

    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                    {!loading && planning ? <ContentItem>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Link underline="hover" color="inherit" href="/">
                                Home
                            </Link>
                            <Link underline="hover" color="inherit" href="/planning">
                                Plannings list
                            </Link>
                            <Typography color="text.primary">{t('Monthly planning')} ({planning.yearMonth})</Typography>
                        </Breadcrumbs>
                        <h1>{t('Monthly planning')} ({planning.yearMonth})</h1>
                        <PlanningMonthStepper
                            planning={planning}
                            expenseCategories={expenseCategories}
                            onSaveExpenseCategory={onSaveExpenseCategory}
                        />
                    </ContentItem> : <LoadingItem><CircularProgress/></LoadingItem>}
                </Grid>
            </Grid>
        </div>
    )
}

export default PlanningMonth;