import {
    addMonths,
    addWeeks,
    addYears,
    differenceInSeconds,
    endOfMonth,
    format,
    formatISO,
    isLastDayOfMonth,
    parseISO
} from "date-fns";

export const defaultDateTimeStringFormat = 'yyyy-MM-dd HH:mm:ss';
export const defaultDateTimeFormat = "yyyy-MM-dd'T'HH:mm:ss.SSS";
export const defaultDateFormat = 'yyyy-MM-dd';

export const formatDateTimeString = (str: string) => format(parseISO(str), defaultDateTimeStringFormat);
export const formatDateString = (str: string) => format(parseISO(str), defaultDateFormat);

export const formatDate = (date: Date) => {
    return formatISO(date, {representation: 'date'});
};

export const formatDateTimeToString = (date: Date) => {
    return format(date, defaultDateTimeStringFormat);
};

export const formatDateTime = (date: Date) => {
    return format(date, defaultDateTimeFormat);
};

export const parseISODate = (value: any) => {
    return parseISO(value);
};

export const parseISODateToDiffInSeconds = (value: any) => {
    const start = new Date();
    const end = parseISO(value);
    return differenceInSeconds(end, start);
};

export const prepareSequenceOfDates = (startDate: Date, frequency: string, count: number) => {
    const dateList: Date[] = [];
    let currentDate = startDate;
    const isLast = isLastDayOfMonth(startDate);
    for (let i = 0; i < count; i++) {
        switch (frequency) {
            case "0":
                currentDate = addMonths(currentDate, 1);
                break;
            case "1":
                currentDate = addWeeks(currentDate, 1);
                break;
            case "2":
                currentDate = addYears(currentDate, 1);
        }

        dateList.push(isLast && frequency === "0" ? endOfMonth(currentDate) : currentDate);

    }
    return dateList;
};