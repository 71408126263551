import * as React from 'react';
import {Ref, useRef, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import RevenueForm from "../revenue-form/RevenueForm";
import RevenueFormMethods from "../../interfaces/RevenueFormMethods";
import RevenueRequestDto from "../../dto/RevenueRequestDto";
import {parseISODate} from "../../utils/date.utils";

interface Props {
    budgetBusinessKey: string,
    onSave: any
    dateFrom: string
}

export default function RevenueCreateDialog(props: Props) {
    const {t} = useTranslation();
    const {budgetBusinessKey, onSave, dateFrom} = props;
    const [open, setOpen] = useState(false);
    const childRef: Ref<RevenueFormMethods> = useRef(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnSave = (data: RevenueRequestDto) => {
        setOpen(false);
        onSave(data);
    }

    return (
        <div>
            <Button onClick={handleClickOpen} variant="contained">{t('Create new')}</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('New revenue')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <RevenueForm
                        ref={childRef}
                        budgetBusinessKey={budgetBusinessKey}
                        onSave={handleOnSave}
                        showSaveButton={false}
                        defaultReceiptAt={parseISODate(dateFrom)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button variant="contained" type="submit"
                            onClick={() => childRef.current?.onSubmitVoid()}
                    >{t('Save')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};