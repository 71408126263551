import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import PlannedExpenseDto from "../../dto/PlannedExpenseDto";
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import {format, parseISO} from "date-fns";
import {defaultDateTimeFormat, formatDateTime, parseISODate, prepareSequenceOfDates} from "../../utils/date.utils";
import {savePlannedExpense} from "../../api/api";
import PlannedExpenseRequestDto from "../../dto/PlannedExpenseRequestDto";
import {sleep} from "../../constants/sleeper";
import {useTranslation} from "react-i18next";
import {LoadingItem} from "../loading-item/LoadingItem";
import ContentItem from "../content-item/ContentItem";


interface Props {
    plannedExpense: PlannedExpenseDto,
    onSave: any
}

export default function PlannedExpenseRepeatDialog(props: Props) {
    const {t} = useTranslation();
    const {plannedExpense, onSave} = props;
    const {register, getValues, handleSubmit, setValue, trigger, formState: {errors}} = useForm();
    const [open, setOpen] = useState(false);
    const [repeatFrequency, setRepeatFrequency] = useState('0');
    const [timesAmount, setTimesAmount] = useState(1);
    const [saveDates, setSaveDates] = useState<Date[]>([]);
    const [loading, setLoading] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onChangeRepeatFrequency = (event: any, value: string) => {
        setRepeatFrequency(value);
        setValue('repeatFrequency', value);
        prepareDates(parseISO(plannedExpense.toPayAt), value, timesAmount);
    }

    const onChangeTimesAmount = (event: any) => {
        setTimesAmount(event.target.value);
        setValue('timesAmount', event.target.value);
        prepareDates(parseISO(plannedExpense.toPayAt), repeatFrequency, event.target.value);
    }

    const prepareDates = (startDate: Date, frequency: string, count: number) => {
        const dates = prepareSequenceOfDates(startDate, frequency, count);
        setSaveDates(dates);
    };

    const preparePlannedExpense = (toPayAt: string) => {
        const {businessKey, updatedAt, createdAt, category, ...rest} = plannedExpense;
        return {...rest, toPayAt, categoryBusinessKey: category.businessKey} as PlannedExpenseRequestDto;
    }

    const savePlannedExpenses = async () => {
        setLoading(true);
        for (const saveDate of saveDates) {
            await sleep(50);
            const toPayAt = formatDateTime(saveDate);
            console.log(toPayAt);
            await savePlannedExpense(preparePlannedExpense(toPayAt));
        }
    };

    const onSubmit = async () => {
        const isValid = await trigger();
        if (isValid) {
            savePlannedExpenses().then(() => {
                onSave();
                setOpen(false);
                setLoading(false);
            });
        }
    };

    const initForm = () => {
        prepareDates(parseISODate(plannedExpense.toPayAt), repeatFrequency, timesAmount);
    };

    useEffect(() => {
        initForm();
    }, []);

    return (<div>
            <IconButton onClick={handleClickOpen}>
                <EventRepeatIcon/>
            </IconButton>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('Repeat planned expense')}</DialogTitle>
                {loading ? <DialogContent>
                        <Box sx={{width: '100%'}}>
                            <Stack spacing={2}>
                                <LoadingItem><CircularProgress/></LoadingItem>
                            </Stack>
                        </Box>
                    </DialogContent>
                    : <DialogContent>
                        <DialogContentText>
                            {t('Description')}: {plannedExpense.description}<br/>
                            {t('Amount')}: {plannedExpense.amount} zł.<br/>
                            {t('Category')}: {plannedExpense.category?.name}<br/>
                            {t('To pay at')}: {plannedExpense.toPayAt}
                        </DialogContentText>
                        <form>
                            <Box sx={{width: '100%'}}>
                                <Stack spacing={2}>
                                    <ContentItem>
                                        <FormControl fullWidth>
                                            <FormLabel
                                                id="planned-expense-repeat-frequency-label">{t('Frequency')}</FormLabel>
                                            <RadioGroup
                                                aria-labelledby="planned-expense-repeat-frequency-label"
                                                value={repeatFrequency}
                                                {...register("repeatFrequency", {required: false})}
                                                onChange={onChangeRepeatFrequency}
                                            >
                                                <FormControlLabel value="0" control={<Radio/>} label={t('Monthly')}/>
                                                <FormControlLabel value="1" control={<Radio/>} label={t('Weekly')}/>
                                                <FormControlLabel value="2" control={<Radio/>} label={t('Yearly')}/>
                                            </RadioGroup>
                                            {errors.repeatFrequency &&
                                                <FormHelperText>{t('Repeat frequency is required')}</FormHelperText>}
                                        </FormControl>
                                    </ContentItem>
                                    <ContentItem>
                                        <TextField fullWidth
                                                   id="times-amount"
                                                   label={t('Times amount')}
                                                   variant="outlined"
                                                   type={"number"}
                                                   inputProps={{min: 0, max: 12}}
                                                   value={timesAmount}
                                                   {...register("timesAmount", {required: true})}
                                                   onChange={onChangeTimesAmount}
                                        />
                                        {errors.timesAmount &&
                                            <FormHelperText>{t('Times amount is required')}</FormHelperText>}
                                    </ContentItem>
                                    {saveDates.length > 0 ? <ContentItem>
                                            {t('Generated dates')}:
                                            <ul>
                                                {saveDates.map((saveDate) =>
                                                    <li key={saveDate.toISOString()}>{format(saveDate, defaultDateTimeFormat)}
                                                    </li>
                                                )}
                                            </ul>
                                        </ContentItem>
                                        : null}
                                </Stack>
                            </Box>
                        </form>
                    </DialogContent>}
                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button type="submit" onClick={handleSubmit(onSubmit)}>{t('Save')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};