import * as React from 'react';
import {useEffect} from 'react';
import RealExpenseSummaryDto from "../../dto/RealExpenseSummaryDto";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import {useTranslation} from "react-i18next";
import {RealExpensesPieChart} from "../real-expenses-pie-chart/RealExpensesPieChart";
import RealExpenseSummaryAccordion from "../real-expense-summary-accordion/RealExpenseSummaryAccordion";
import ContentItem from "../content-item/ContentItem";

interface Props {
    expenseCategories: ExpenseCategoryDto[],
    expensesSummary: RealExpenseSummaryDto
}

export default function RealExpensesByCategory(props: Props) {
    const {t} = useTranslation();
    const {expenseCategories, expensesSummary} = props;

    useEffect(() => {
    }, [expenseCategories, expensesSummary]);

    return (
        <div>
            {expensesSummary.totalAmount !== '0.00' ?
                <ContentItem>
                    <h3>{t('Real expenses by category')}</h3>
                    {expensesSummary.balanceByCategoryAmount && expenseCategories.length > 0
                        ? <div>
                            <RealExpensesPieChart summary={expensesSummary} categories={expenseCategories}/>
                            <RealExpenseSummaryAccordion categories={expenseCategories} summary={expensesSummary}/>
                        </div> : null}

                </ContentItem> : null}
        </div>
    );
};