import {TableHead} from "@mui/material";
import React, {useState} from "react";
import PageableDto from "../../dto/PageableDto";
import UserMessageDto from "../../dto/UserMessageDto";
import {useTranslation} from "react-i18next";
import {defaultPage, defaultPageSize, rowsPerPageOptions} from "../../constants/pagination";
import {getCookieTime} from "../../utils/cookie.utils";
import Cookies from "universal-cookie";
import TablePagination from "@mui/material/TablePagination";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import UserMessagesListItem from "../user-messages-list-item/UserMessagesListItem";
import {userMessageMarkAsRead} from "../../api/api";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";

interface Props {
    pageable: PageableDto<UserMessageDto[]>;
    loadData: any;
}

export default function UserMessagesList(props: Props) {
    const {t} = useTranslation();
    const {pageable, loadData} = props;
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const cookies = new Cookies();

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
        loadData(newPage, pageSize);
    };

    const handleRead = async (id: string) => {
        await userMessageMarkAsRead(id);
    }

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        loadData(0, newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
    };

    return (
        <div>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            pageable.content.map(item => (
                                    <UserMessagesListItem
                                        key={`user-message-${item.id}`}
                                        item={item}
                                        onRead={handleRead}
                                    />
                                )
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={rowsPerPageOptions}
                                colSpan={3}
                                count={pageable.totalElements}
                                rowsPerPage={pageSize}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                        'aria-label': `${t('rows per page')}`,
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>

            </TableContainer>

        </div>
    );
}