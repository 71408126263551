import * as React from 'react';
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

function PageNotFound() {
    const {t} = useTranslation();
    return (
        <div>
            <h1>{t('Page not found')}</h1>
            <p>{t('Sorry this page not found! Go to main page')} <Button
                component={Link}
                to="/"
                variant="contained">
                Home
            </Button></p>
        </div>
    );
};

export default PageNotFound;