import * as React from 'react';
import {useContext} from 'react';
import {Backdrop, CircularProgress} from "@mui/material";
import {AppContext} from "../../context/app";
import AppContextProps from "../../props/AppContextProps";

export default function AppLoader() {
    const {appLoading} = useContext(AppContext) as AppContextProps;

    return (
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={appLoading}>
            <CircularProgress color="inherit"/>
        </Backdrop>
    );
};
