import * as React from 'react';
import {useEffect, useState} from 'react';
import {Checkbox, CircularProgress, FormControlLabel} from "@mui/material";
import PlannedExpenseList from "../../components/planned-expense-list/PlannedExpenseList";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import {useTranslation} from "react-i18next";
import {LoadingItem} from "../../components/loading-item/LoadingItem";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import SearchPlannedExpensesRequestDto from "../../dto/SearchPlannedExpensesRequestDto";
import {searchPlannedExpensesPaged} from "../../api/api";
import PageableDto from "../../dto/PageableDto";
import PlannedExpenseDto from "../../dto/PlannedExpenseDto";
import {Timeout} from "react-number-format/types/types";
import {getCookieTime} from "../../utils/cookie.utils";
import Cookies from "universal-cookie";

interface Props {
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string,
    expenseCategories: ExpenseCategoryDto[],
    afterChange: () => void
}

function PlannedExpenseWidget(props: Props) {
    const cookies = new Cookies();
    const {t} = useTranslation();
    const emptyPageable = {
        content: [],
        totalPages: 0,
        totalElements: 0,
        size: 0,
        number: 0
    } as PageableDto<PlannedExpenseDto[]>;
    const [pageable, setPageable] = useState<PageableDto<PlannedExpenseDto[]>>(emptyPageable);
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [query, setQuery] = useState('');
    const [loadTimeoutId, setLoadTimeoutId] = useState<Timeout | undefined>(undefined);
    const [hidePaid, setHidePaid] = useState(
        cookies.get('hidePaid') === 'true'
    );
    const [loading, setLoading] = useState(true);
    const {
        budgetBusinessKey,
        dateFrom,
        dateTo,
        expenseCategories,
        afterChange
    } = props;

    const delayLoadPlannedExpenses = (request: SearchPlannedExpensesRequestDto) => {
        if (typeof loadTimeoutId === 'number') {
            clearTimeout(loadTimeoutId);
            setLoadTimeoutId(undefined);
        }

        const newId = setTimeout(() => {
            loadPlanedExpenses(request).then();
        }, 1500);
        setLoadTimeoutId(newId);
    };

    const loadPlanedExpenses = async (request: SearchPlannedExpensesRequestDto) => {
        setLoading(true)
        await searchPlannedExpensesPaged(request, request.page, request.pageSize).then(response => {
            setPageable(response);
            setLoading(false);
        });
    };

    const onChangeQuery = (event: any) => {
        let cleanedValue = event.target.value.replace(/[^\w\s.,]/g, "");
        cleanedValue = cleanedValue.replace(",", ".");
        setQuery(cleanedValue);
        const request = {
            budgetBusinessKey: budgetBusinessKey,
            dateFrom: dateFrom,
            dateTo: dateTo,
            q: cleanedValue,
            hidePaid: hidePaid,
            page: page,
            pageSize: pageSize
        } as SearchPlannedExpensesRequestDto;
        delayLoadPlannedExpenses(request);
    };

    const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setHidePaid(event.target.checked);
        const expires = getCookieTime();
        cookies.set('hidePaid', event.target.checked, {expires});
        const request = {
            budgetBusinessKey: budgetBusinessKey,
            dateFrom: dateFrom,
            dateTo: dateTo,
            q: query,
            hidePaid: event.target.checked,
            page: page,
            pageSize: pageSize
        } as SearchPlannedExpensesRequestDto;
        await loadPlanedExpenses(request);
    };

    const onChangePage = async (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        const request = getDefaultSearchRequest(budgetBusinessKey, dateFrom, dateTo);
        setPage(newPage);
        request.page = newPage;
        await loadPlanedExpenses(request);
    }

    const onChangePageSize = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});

        const request = getDefaultSearchRequest(budgetBusinessKey, dateFrom, dateTo);
        request.page = 0;
        request.pageSize = newPageSize;
        await loadPlanedExpenses(request);
    }

    const getDefaultSearchRequest = (budgetBusinessKey: string, dateFrom: string, dateTo: string) => {
        return {
            budgetBusinessKey: budgetBusinessKey,
            dateFrom: dateFrom,
            dateTo: dateTo,
            page: defaultPage,
            pageSize: defaultPageSize,
            q: '',
            hidePaid: hidePaid
        };
    }

    const onLoadData = () => {
        loadPlanedExpenses(getDefaultSearchRequest(budgetBusinessKey, dateFrom, dateTo)).then(() => {
            afterChange();
        });
    }

    useEffect(() => {
        if (budgetBusinessKey !== undefined && budgetBusinessKey !== '') {
            loadPlanedExpenses(getDefaultSearchRequest(budgetBusinessKey, dateFrom, dateTo)).then();
        }
    }, [budgetBusinessKey, dateFrom, dateTo, expenseCategories]);

    return (
        <div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={hidePaid}
                        onChange={handleCheckboxChange}
                        color="primary"
                    />
                }
                label={t('Hide paid')}
            />
            {!loading ? <PlannedExpenseList
                pageable={pageable}
                query={query}
                page={page}
                pageSize={pageSize}
                onChangePage={onChangePage}
                onChangePageSize={onChangePageSize}
                loadData={onLoadData}
                onChangeQuery={onChangeQuery}
            /> : <LoadingItem><CircularProgress/></LoadingItem>}
        </div>
    );
}

export default PlannedExpenseWidget;