import * as React from 'react';
import {useContext} from 'react';
import BudgetForm from "../../components/budget-form/BudgetForm";
import {AppContext} from "../../context/app";
import AppContextProps from "../../props/AppContextProps";
import {useNavigate} from "react-router-dom";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import {Breadcrumbs} from "@mui/material";
import {t} from "i18next";

function CreateFirstBudget() {
    const {onSaveBudget} = useContext(AppContext) as AppContextProps;
    const navigate = useNavigate();
    const handleOnSaveBudget = (data: any) => {
        onSaveBudget(data).then(() => {
            navigate("/");
        });
    };

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href="/">
                    Home
                </Link>
                <Typography color="text.primary">{t('Create first budget')}</Typography>
            </Breadcrumbs>
            <h1>Witaj w MojeFinanse!</h1>
            <p>Utwórz swój pierwszy budżet, aby móc nim zarządzać.</p>
            <BudgetForm onSaveBudget={handleOnSaveBudget}/>
        </div>
    );
}

export default CreateFirstBudget;