import * as React from 'react';
import {useEffect, useState} from 'react';
import PageableDto from "../../dto/PageableDto";
import RevenueDto from "../../dto/RevenueDto";
import {getRevenues, saveRevenue} from "../../api/api";
import {defaultPage, defaultPageSize} from "../../constants/pagination";
import {useTranslation} from "react-i18next";
import RevenueRequestDto from "../../dto/RevenueRequestDto";
import RevenueList from "../revenue-list/RevenueList";
import RevenueCreateDialog from "../revenue-create-dialog/RevenueCreateDialog";
import {Alert, CircularProgress} from "@mui/material";
import {LoadingItem} from "../loading-item/LoadingItem";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {getCookieTime} from "../../utils/cookie.utils";
import Cookies from "universal-cookie";

interface Props {
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string
    afterSave: (budgetBusinessKey: string, dateFrom: string, dateTo: string) => void,
    onClickContinue: () => void,
    revenueSummaryValid: boolean
}

export default function RevenuePlanning(props: Props) {
    const {t} = useTranslation();
    const cookies = new Cookies();
    const {
        budgetBusinessKey,
        dateFrom,
        dateTo,
        afterSave,
        onClickContinue,
        revenueSummaryValid
    } = props;
    const emptyPageable = {
        content: [],
        totalPages: 0,
        totalElements: 0,
        size: 0,
        number: 0
    } as PageableDto<RevenueDto[]>;
    const [pageable, setPageable] = useState<PageableDto<RevenueDto[]>>(emptyPageable);
    const [page, setPage] = useState(defaultPage);
    const [pageSize, setPageSize] = useState(defaultPageSize);
    const [loading, setLoading] = useState(true);

    const loadRevenues = (budgetBusinesskey: string, page: number, size: number) => {
        setLoading(true);
        getRevenues(budgetBusinesskey, page, size, dateFrom, dateTo).then(response => {
            setPageable(response);
            setLoading(false);
        });
    };

    const onSaveRevenue = (data: RevenueRequestDto) => {
        setLoading(true);
        saveRevenue(data).then(() => {
            loadRevenues(budgetBusinessKey, defaultPage, defaultPageSize);
            afterSave(budgetBusinessKey, dateFrom, dateTo);
        });
    };

    const loadData = (page: number, pageSize: number) => {
        loadRevenues(budgetBusinessKey, page, pageSize);
    }

    const onChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number
    ) => {
        setPage(newPage);
        loadData(newPage, pageSize);
    };

    const onChangePageSize = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const newPageSize = parseInt(event.target.value, 10);
        setPage(0);
        setPageSize(newPageSize);
        loadData(0, newPageSize);
        const expires = getCookieTime();
        cookies.set('pageSize', newPageSize, {expires});
    };

    useEffect(() => {
    }, [revenueSummaryValid]);

    useEffect(() => {
        if (budgetBusinessKey !== undefined && budgetBusinessKey !== '') {
            loadRevenues(budgetBusinessKey, defaultPage, defaultPageSize);
        }
    }, [budgetBusinessKey, dateFrom, dateTo])

    return (
        <div>
            {!loading ?
                <div>
                    <div>
                        <RevenueCreateDialog
                            budgetBusinessKey={budgetBusinessKey}
                            onSave={onSaveRevenue}
                            dateFrom={dateFrom}
                        />
                    </div>
                    <div>{!revenueSummaryValid ? <Alert severity="info">
                        {t('Add revenues to continue.')}
                    </Alert> : null}</div>
                    <div>
                        <Box sx={{mb: 2}}>
                            <div>
                                <Button
                                    variant="contained"
                                    disabled={!revenueSummaryValid}
                                    onClick={onClickContinue}
                                    sx={{mt: 1, mr: 1}}
                                >
                                    {t('Complete')}
                                </Button>
                            </div>
                        </Box>
                    </div>
                    <div>
                        <h2>{t('Revenues list')}</h2>
                        <RevenueList
                            pageable={pageable}
                            page={page}
                            onChangePage={onChangePage}
                            pageSize={pageSize}
                            onChangePageSize={onChangePageSize}
                            loadData={() => loadData(page, pageSize)}
                            afterChange={() => afterSave(budgetBusinessKey, dateFrom, dateTo)}
                        />
                    </div>
                </div>
                : <LoadingItem><CircularProgress/></LoadingItem>}
        </div>
    );
}