import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use(initReactI18next).init({
    debug: true,
    resources: {
        en: {
            translation: {
                "Welcome": "Welcome",
                "Login": "Login",
                "Username": "Username",
                "Password": "Password",
                "Field is required": "Field is required",
                "Real expenses": "Expenses",
                "Create new expense": "Create new expense",
                "Create expense": "Create expense",
                "Revenues": "Revenues",
                "Planned expenses": "Planned expenses",
                "Planned expense": "Planned expense",
                "New planned expense": "New planned expense",
                "Savings goals": "Savings goals",
                "Budgets": "Budgets",
                "Budget": "Budget",
                "Profile": "Profile",
                "Logout": "Logout",
                "Filters": "Filters",
                "Month": "Month",
                "Select budget": "Select budget",
                "No budgets": "No budgets",
                "Create your first budget": "Create your first budget",
                "Create budget": "Create budget",
                "Your budget": "Your budget",
                "Real expenses by category": "Real expenses by category",
                "Not available": "Not available",
                "Real expenses list": "Expenses list",
                "Show list": "Show list",
                "Search": "Search",
                "Clear search value": "Clear search value",
                "Description": "Description",
                "Amount": "Amount",
                "Total amount": "Total amount",
                "Balance": "Balance",
                "Category": "Category",
                "Planned": "Planned",
                "Paid at": "Paid at",
                "Actions": "Actions",
                "No data": "No data",
                "rows per page": "rows per page",
                "Edit real expense": "Edit expense",
                "Category is required": "Category is required",
                "Category (from planned expense)": "Category (from planned expense)",
                "Description is required": "Description is required",
                "Amount is required": "Amount is required",
                "Cancel": "Cancel",
                "Save": "Save",
                "Submit": "Submit",
                "Are you sure delete real expense": "Are you sure delete real expense",
                "Create new": "Create new",
                "List": "List",
                "Create new budget": "Create new budget",
                "Budget list": "Budget list",
                "Name": "Name",
                "Name is required": "Name is required",
                "Create": "Create",
                "Page not found": "Page not found",
                "Sorry this page not found! Go to main page": "Sorry this page not found! Go to main page",
                "To pay at": "To pay at",
                "Type amount value.": "Type amount value.",
                "Planned expense balance": "Planned expense balance",
                "Continue": "Continue",
                "Back": "Back",
                "Reset": "Reset",
                "Last step": "Last step",
                "Select paid date.": "Select paid date.",
                "Paid At is required": "Paid At is required",
                "Select planned expense or category.": "Select planned expense or category.",
                "Type expense description.": "Type expense description.",
                "Summary": "Summary",
                "Create new expense category": "Create new expense category",
                "Create expense category": "Create expense category",
                "No expense categories": "No expense categories",
                "No planned expenses": "No planned expenses",
                "No expenses": "No expenses",
                "New revenue": "New revenue",
                "Revenues list": "Revenues list",
                "Receipt at": "Receipt at",
                "Edit revenue": "Edit revenue",
                "Repeat revenue": "Repeat revenue",
                "Frequency": "Frequency",
                "Monthly": "Monthly",
                "Weekly": "Weekly",
                "Yearly": "Yearly",
                "Times amount": "Times amount",
                "Times amount is required": "Times amount is required",
                "Generated dates": "Generated dates",
                "User profile": "User profile",
                "Hello": "Hello",
                "Update your password": "Update your password",
                "Old password": "Old password",
                "Confirm password": "Confirm password",
                "Fields should be same.": "Fields should be same.",
                "Wrong password repeat!": "Wrong password repeat!",
                "Real expenses history": "Expenses history",
                "Are you sure delete revenue": "Are you sure delete revenue",
                "Repeat planned expense": "Repeat planned expense",
                "Repeat frequency is required": "Repeat frequency is required",
                "Are you sure delete planned expense": "Are you sure delete planned expense",
                "Total expenses value": "Total expenses value",
                "Planned balance": "Planned balance",
                "Budget balance": "Budget balance",
                "Messages": "Messages",
                "Expenses progress": "Expenses progress",
                "Unplanned": "Unplanned vs. Planned",
                "Unplanned expenses value": "Unplanned expenses value",
                "Hide paid": "Hide paid",
                "Is planned paid?": "Is planned paid?",
                "Create first revenue": "Create first revenue",
                "Mark as paid": "Mark as paid",
                "Mark as unpaid": "Mark as unpaid",
                "Monthly planning": "Monthly planning",
                "Mark as planned": "Mark as planned",
                "Edit": "Edit",
                "Bad month value! Pattern is yyyy-MM.": "Bad month value! Pattern is yyyy-MM.",
                "You don't have any monthly plans yet.": "You don't have any monthly plans yet.",
                "Start first planning": "Start first planning",
                "The current month is not planned.": "The current month is not planned.",
                "Start planning": "Start planning",
                "The planning for the current month has not been finished.": "The planning for the current month has not been finished.",
                "Continue planning": "Continue planning",
                "The next month is not planned.": "The next month is not planned.",
                "The planning for the next month has not been finished.": "The planning for the next month has not been finished.",
                "Save draw": "Save draw",
                "Complete": "Complete",
                "The total planned expenses exceed the sum of revenues!": "The total planned expenses exceed the sum of revenues!"
            }
        },
        pl: {
            translation: {
                "Welcome": "Witaj",
                "Login": "Zaloguj się",
                "Username": "Nazwa użytkownika",
                "Password": "Hasło",
                "Field is required": "Pole jest wymagane",
                "Real expenses": "Wydatki",
                "Create new expense": "Utwórz nowy wydatek",
                "Create expense": "Utwórz wydatek",
                "Revenues": "Przychody",
                "Planned expenses": "Zaplanowane wydatki",
                "Planned expense": "Planowany wydatek",
                "New planned expense": "Nowy planowany wydatek",
                "Savings goals": "Cele oszczędnościowe",
                "Budgets": "Budżety",
                "Budget": "Budżet",
                "Profile": "Profil",
                "Logout": "Wyloguj się",
                "Filters": "Filtry",
                "Month": "Miesiąc",
                "Select budget": "Wybierz budżet",
                "No budgets": "Brak budżetów",
                "Create your first budget": "Utwórz swój pierwszy budżet",
                "Create budget": "Utwórz budżet",
                "Your budget": "Twój budżet",
                "Real expenses by category": "Wydatki według kategorii",
                "Not available": "Niedostępne",
                "Real expenses list": "Lista wydatków",
                "Show list": "Pokaż listę",
                "Search": "Szukaj",
                "Clear search value": "Wyczyść",
                "Description": "Opis",
                "Amount": "Kwota",
                "Total amount": "Łączna kwota",
                "Balance": "Saldo",
                "Category": "Kategoria",
                "Planned": "Planowany",
                "Paid at": "Data płatności",
                "Actions": "Akcje",
                "No data": "Brak danych",
                "rows per page": "elementów na stronie",
                "Edit real expense": "Edytuj wydatek",
                "Category is required": "Kategoria jest wymagana",
                "Category (from planned expense)": "Kategoria (pobrana z planowanego wydatku)",
                "Description is required": "Opis jest wymagany",
                "Amount is required": "Kwota jest wymagana",
                "Cancel": "Anuluj",
                "Save": "Zapisz",
                "Submit": "Wyślij",
                "Are you sure delete real expense": "Czy na pewno chcesz usunąć wydatek",
                "Create new": "Utwórz nowy",
                "List": "Lista",
                "Create new budget": "Utwórz nowy budżet",
                "Budget list": "Lista budżetów",
                "Name": "Nazwa",
                "Name is required": "Nazwa jest wymagana",
                "Create": "Utwórz",
                "Page not found": "Nie odnaleziono strony",
                "Sorry this page not found! Go to main page": "Przepraszamy ale strona nie istnieje! Przejdź do strony głównej",
                "To pay at": "Data zapłaty",
                "Type amount value.": "Wpisz kwotę",
                "Planned expense balance": "Saldo planowanego wydatku",
                "Continue": "Dalej",
                "Back": "Wstecz",
                "Reset": "Resetuj",
                "Last step": "Ostatni krok",
                "Select paid date.": "Zaznacz datę płatności.",
                "Paid At is required": "Data płatności jest wymagana",
                "Select planned expense or category.": "Zaznacz planowany wydatek lub kategorię.",
                "Type expense description.": "Wpisz opis wydatku.",
                "Summary": "Podsumowanie",
                "Create new expense category": "Utwórz nową kategorię wydatków",
                "Create expense category": "Utwórz kategorię wydatków",
                "No expense categories": "Brak kategorii",
                "No planned expenses": "Brak planowanych wydatków",
                "No expenses": "Brak wydatków",
                "New revenue": "Nowy przychód",
                "Revenues list": "Lista przychodów",
                "Receipt at": "Data otrzymania",
                "Edit revenue": "Edytuj przychód",
                "Repeat revenue": "Powtórz przychód",
                "Frequency": "Częstotliwość",
                "Monthly": "Miesięcznie",
                "Weekly": "Tygodniowo",
                "Yearly": "Rocznie",
                "Times amount": "Ilość powtórzeń",
                "Times amount is required": "Ilość powtórzeń jest wymagana",
                "Generated dates": "Wygenerowane daty",
                "User profile": "Profil użytkownika",
                "Hello": "Witaj",
                "Update your password": "Zaktualizuj swoje hasło",
                "Old password": "Stare hasło",
                "Confirm password": "Potwierdź hasło",
                "Fields should be same.": "Pola muszą być takie same.",
                "Wrong password repeat!": "Błędne hasło, musisz je poprawić!",
                "Real expenses history": "Historia wydatków",
                "Are you sure delete revenue": "Czy na pewno chcesz usunąć przychód",
                "Repeat planned expense": "Powtórz planowany wydatek",
                "Repeat frequency is required": "Częstotliwość powtórzeń jest wymagana",
                "Are you sure delete planned expense": "Czy na pewno chcesz usunąć zaplanowany wydatek",
                "Total expenses value": "Wartość wydatków",
                "Planned balance": "Saldo planowanych",
                "Budget balance": "Saldo budżetu",
                "Messages": "Wiadomości",
                "Expenses progress": "Postęp wydatków",
                "Unplanned vs. Planned": "Nieplanowane vs. planowane",
                "Unplanned expenses value": "Wartość nieplanowanych",
                "Hide paid": "Ukryj opłacone",
                "Is planned paid?": "Czy planowany opłacony?",
                "Create first revenue": "Utwórz pierwszy przychód",
                "Mark as paid": "Oznacz jako opłacony",
                "Mark as unpaid": "Oznacz jako nieopłacony",
                "Monthly planning": "Planowanie miesięczne",
                "Mark as planned": "Zaplanowane",
                "Edit": "Edytuj",
                "Bad month value! Pattern is yyyy-MM.": "Błędna wartość! Wzór yyyy-MM",
                "You don't have any monthly plans yet.": "Nie masz jeszcze żadnych planowań miesięcznych.",
                "Start first planning": "Rozpocznij pierwsze planowanie",
                "The current month is not planned.": "Obecny miesiąc nie jest zaplanowany",
                "Start planning": "Rozpocznij planowanie",
                "The planning for the current month has not been finished.": "Planowanie obecnego miesiąca nie zostało zakończone.",
                "Continue planning": "Dokończ planowanie",
                "The next month is not planned.": "Następny miesiąc nie jest zaplanowany.",
                "The planning for the next month has not been finished.": "Planowanie następnego miesiąca nie zostało zakończone.",
                "Save draw": "Zapisz szkic",
                "Complete": "Gotowe",
                "The total planned expenses exceed the sum of revenues!": "Suma planowanych wydatków jest wyższa niż przychody!"
            }
        }
    },
    lng: "pl",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false
    },
    returnNull: false
});
