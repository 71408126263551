import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {pl} from "date-fns/locale";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {parseISO} from "date-fns";
import RealExpenseDto from "../../dto/RealExpenseDto";
import {getPlannedExpensesBalance, updateRealExpense} from "../../api/api";
import RealExpenseUpdateRequestDto from "../../dto/RealExpenseUpdateRequestDto";
import ExpenseDescriptionAutocomplete from "../expense-description-autocomplete/ExpenseDescriptionAutocomplete";
import PlannedExpenseAutocomplete from "../planned-expense-autocomplete/PlannedExpenseAutocomplete";
import PlannedExpenseDropdownDto from "../../dto/PlannedExpenseDropdownDto";
import PlannedExpenseBalanceDto from "../../dto/PlannedExpenseBalanceDto";
import {getBalanceTextStyle} from "../../utils/balance.utils";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ExpenseCategoryAutocomplete from "../expense-category-autocomplete/ExpenseCategoryAutocomplete";
import ExpenseCategoryForm from "../expense-category-form/ExpenseCategoryForm";
import {useTranslation} from "react-i18next";
import ExpenseCategoryRequestDto from "../../dto/ExpenseCategoryRequestDto";
import {formatDateTime} from "../../utils/date.utils";
import FormItem from "../form-item/FormItem";


interface Props {
    realExpense: RealExpenseDto;
    plannedExpenses: PlannedExpenseDropdownDto[];
    categories: ExpenseCategoryDto[];
    onSave: any;
    onSaveExpenseCategory: (request: ExpenseCategoryRequestDto) => void;
}

export default function RealExpenseEditDialog(props: Props) {
    const {t} = useTranslation();
    const defaultBalance = {
        balance: "0.00"
    };
    const {
        plannedExpenses,
        categories,
        realExpense,
        onSave,
        onSaveExpenseCategory
    } = props;
    const {register, getValues, reset, setValue, formState: {errors}} = useForm();
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState(realExpense.amount);
    const [isCategoriesVisible, setIsCategoriesVisible] = useState(true);
    const [plannedExpenseBalance, setPlannedExpenseBalance] = useState<PlannedExpenseBalanceDto>(defaultBalance);
    const [selectedCategoryName, setSelectedCategoryName] = useState<string>('');
    const findPlannedExpense = (results: PlannedExpenseDropdownDto[], businessKey: string) => {
        const result = results.find(item => item.businessKey === businessKey);
        if (result === undefined) {
            return null;
        }

        return result;
    };
    const initialPlannedExpense = findPlannedExpense(plannedExpenses, realExpense.plannedExpenseBusinessKey);
    const [selectedPlannedExpense, setSelectedPlannedExpense] = useState<PlannedExpenseDropdownDto | null>(initialPlannedExpense);

    const parseDate = (date: string) => {
        return parseISO(date);
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const loadPlannedExpenseBalance = (businessKey: string) => {
        getPlannedExpensesBalance(businessKey).then(result => {
            setPlannedExpenseBalance(result);
        });
    }

    const addAmount = (value: string) => {
        setAmount(value);
        setValue("amount", value);
    }

    const onSelectPlannedExpense = (event: any, value: any) => {
        setSelectedPlannedExpense(value);

        if (value !== null) {
            setSelectedCategoryName(value?.category.name || '');
            setValue('plannedExpenseBusinessKey', value?.businessKey);
            setDescription(value?.description);
            setValue('description', value?.description);
            setValue('categoryBusinessKey', value?.category.businessKey);
        } else {
            setSelectedCategoryName('');
        }

        if (open) {
            loadPlannedExpenseBalance(value?.id);
        }

        setIsCategoriesVisible(value === null);
    };

    const onSelectCategory = (event: any, value: ExpenseCategoryDto | null) => {
        setValue('categoryBusinessKey', value?.businessKey);
        setSelectedCategoryName(value !== null ? value.name : '');
    };

    const onSubmit = (data: any) => {
        const request = data as RealExpenseUpdateRequestDto;
        updateRealExpense(realExpense.businessKey, request).then(() => {
            setOpen(false);
            onSave();
        });
    };

    const onSelectDescription = (event: any, value: string) => {
        setDescription(value);
        setValue('description', value);
    };

    const handleDateFieldChange = (fieldName: string, date: any) => {
        setValue(fieldName, formatDateTime(date), {shouldValidate: true});
    };

    const onChangeAmount = (event: any) => {
        const val = event.target.value.replace(",", ".");
        setValue("amount", val);
        setAmount(val);
    }

    const initForm = () => {
        onSelectPlannedExpense(null, initialPlannedExpense);
        onSelectDescription(null, realExpense.description);
        handleDateFieldChange("paidAt", parseDate(realExpense.paidAt));
        setValue("amount", realExpense.amount);
        setValue("categoryBusinessKey", realExpense.category.businessKey);
    };

    useEffect(() => {
        initForm();
    }, []);

    return (
        <div>
            <IconButton onClick={handleClickOpen}>
                <EditIcon/>
            </IconButton>
            {open ? <div>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{t('Edit real expense')}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {t('Edit real expense')}: {realExpense.description}
                        </DialogContentText>
                        <form>
                            <Box sx={{width: '100%'}}>
                                <Stack spacing={2}>
                                    <FormItem>
                                        <PlannedExpenseAutocomplete
                                            selectedPlannedExpense={selectedPlannedExpense}
                                            plannedExpenses={plannedExpenses}
                                            onSelect={onSelectPlannedExpense}
                                        />
                                        <input
                                            type="hidden" {...register("plannedExpenseBusinessKey", {required: false})} />
                                    </FormItem>
                                    {isCategoriesVisible ? <FormItem>
                                        <ExpenseCategoryAutocomplete
                                            expenseCategories={categories}
                                            onSelect={onSelectCategory}
                                        />
                                        <input type="hidden" {...register("categoryBusinessKey", {required: true})} />
                                        {errors.categoryBusinessKey &&
                                            <FormHelperText>{t('Category is required')}</FormHelperText>}
                                    </FormItem> : <FormItem>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="category-disabled"
                                            label={t('Category (from planned expense)')}
                                            value={selectedCategoryName}
                                        />
                                    </FormItem>}
                                    {isCategoriesVisible ? <FormItem>
                                        <ExpenseCategoryForm
                                            budgetBusinessKey={realExpense.budgetBusinessKey}
                                            onSave={onSaveExpenseCategory}
                                        />
                                    </FormItem> : null}
                                    <FormItem>
                                        <ExpenseDescriptionAutocomplete value={description}
                                                                        onSelect={onSelectDescription}/>
                                        <input type="hidden" {...register("description", {required: true})} />
                                        {errors.description &&
                                            <FormHelperText>{t('Description is required')}</FormHelperText>}
                                    </FormItem>
                                    <FormItem>
                                        <FormControl fullWidth>
                                            <InputLabel htmlFor="adornment-amount">
                                                {t('Amount')}
                                            </InputLabel>
                                            <OutlinedInput
                                                fullWidth
                                                type="string"
                                                inputProps={{
                                                    inputMode: "decimal",
                                                    pattern: "[0-9.,]*",
                                                    autoComplete: "off"
                                                }}
                                                value={amount}
                                                {...register("amount", {required: true})}
                                                onChange={onChangeAmount}
                                                id="adornment-amount"
                                                endAdornment={<InputAdornment position="end">zł.</InputAdornment>}
                                            />
                                            {errors.amount &&
                                                <FormHelperText>{t('Amount is required')}</FormHelperText>}
                                            {selectedPlannedExpense && plannedExpenseBalance ?
                                                <FormHelperText
                                                    style={getBalanceTextStyle(plannedExpenseBalance.balance)}>
                                                    Planned expense balance: {plannedExpenseBalance.balance} zł.
                                                    {parseFloat(plannedExpenseBalance.balance) > 0.0
                                                        ? <IconButton color="primary"
                                                                      aria-label="Set amount"
                                                                      onClick={() => addAmount(plannedExpenseBalance.balance)}>
                                                            <AddShoppingCartIcon/>
                                                        </IconButton>
                                                        : null}
                                                </FormHelperText>
                                                : null}
                                        </FormControl>
                                    </FormItem>
                                    <FormItem>
                                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={pl}>
                                            <DateTimePicker
                                                label={t('Paid at')}
                                                defaultValue={parseDate(realExpense.paidAt)}
                                                onChange={(newValue) => handleDateFieldChange('paidAt', newValue)}
                                            />
                                        </LocalizationProvider>
                                    </FormItem>
                                </Stack>
                            </Box>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t('Cancel')}</Button>
                        <Button type="submit" onClick={() => onSubmit(getValues())}>{t('Save')}</Button>
                    </DialogActions>
                </Dialog>
            </div> : null}
        </div>
    );
};