import * as React from "react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Paper from "@mui/material/Paper";
import PlanningDto from "../../dto/PlanningDto";
import {Link, useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import {Alert, CircularProgress, Step, StepButton, StepContent, Stepper} from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import PlanningUpdateRequestDto from "../../dto/PlanningUpdateRequestDto";
import {getPlannedExpensesSummary, getRevenuesSummary, updatePlanning} from "../../api/api";
import RevenuePlanning from "../revenue-planning/RevenuePlanning";
import {endOfMonth, parse, setHours, setMinutes, setSeconds, startOfMonth} from "date-fns";
import PlannedExpensePlanning from "../planned-expense-planning/PlannedExpensePlanning";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import ExpenseCategoryRequestDto from "../../dto/ExpenseCategoryRequestDto";
import {LoadingItem} from "../loading-item/LoadingItem";
import {formatDateTime} from "../../utils/date.utils";
import RevenueSummaryDto from "../../dto/RevenueSummaryDto";
import PlannedExpenseSummaryDto from "../../dto/PlannedExpenseSummaryDto";
import PlannedExpenseSuggestions from "../planned-expense-suggestions/PlannedExpenseSuggestions";

interface Props {
    planning: PlanningDto,
    expenseCategories: ExpenseCategoryDto[],
    onSaveExpenseCategory: (request: ExpenseCategoryRequestDto) => void
}

export default function PlanningMonthStepper(props: Props) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {
        planning,
        expenseCategories,
        onSaveExpenseCategory
    } = props;
    const defaultStep = 2;
    const [activeStep, setActiveStep] = useState(defaultStep);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [loading, setLoading] = useState(true);
    const defaultSummary = {count: 0, amount: "0.00"};
    const [revenueSummary, setRevenueSummary] = useState<RevenueSummaryDto>(defaultSummary as RevenueSummaryDto);
    const [plannedSummary, setPlannedSummary] = useState<PlannedExpenseSummaryDto>(defaultSummary as PlannedExpenseSummaryDto);
    const initialCompleted = {
        0: false,
        1: false
    };
    const [completed, setCompleted] = useState<{
        [k: number]: boolean;
    }>(initialCompleted);

    const handleNext = () => {
        const data = {
            state: planning.state,
            revenuesCompleted: activeStep === 0 ? true : null,
            plannedExpensesCompleted: activeStep === 1 ? true : null
        } as PlanningUpdateRequestDto;
        update(planning.id, data).then(() => {
            const newCompleted = completed;
            newCompleted[activeStep] = true;
            setCompleted(newCompleted);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        if (step === activeStep) {
            setActiveStep(defaultStep);
            return;
        }
        setActiveStep(step);
    };

    const parseYearMonth = (yearMonth: string) => {
        return parse(yearMonth, 'yyyy-MM', new Date());
    };

    const prepareDateFrom = (yearMonth: string) => {
        const currentDate = parseYearMonth(yearMonth);
        const firstDayOfMonth = startOfMonth(currentDate);
        const dateTime = setSeconds(setMinutes(setHours(firstDayOfMonth, 0), 0), 0);
        return formatDateTime(dateTime);
    };

    const prepareDateTo = (yearMonth: string) => {
        const currentDate = parseYearMonth(yearMonth);
        const firstDayOfMonth = endOfMonth(currentDate);
        const dateTime = setSeconds(setMinutes(setHours(firstDayOfMonth, 23), 59), 59);
        return formatDateTime(dateTime);
    };

    const update = async (id: string, data: PlanningUpdateRequestDto) => {
        return await updatePlanning(id, data);
    };

    const updateAndRedirect = (id: string, state: string, revenuesCompleted: boolean, plannedExpensesCompleted: boolean) => {
        const data = {
            state, revenuesCompleted, plannedExpensesCompleted
        } as PlanningUpdateRequestDto;
        update(id, data).then(() => navigate("/planning"));
    };

    const loadRevenueSummary = async (budgetBusinessKey: string, dateFrom: string, dateTo: string) => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 500));

        getRevenuesSummary(budgetBusinessKey, dateFrom, dateTo).then((summary) => {
            setRevenueSummary(summary);
            setLoading(false);
        });
    };

    const loadPlannedSummary = async (budgetBusinessKey: string, dateFrom: string, dateTo: string) => {
        setLoading(true);
        await new Promise(resolve => setTimeout(resolve, 2000));

        getPlannedExpensesSummary(budgetBusinessKey, dateFrom, dateTo).then((summary) => {
            setPlannedSummary(summary);
            setLoading(false);
        });
    };

    const init = (planning: PlanningDto) => {
        const newDateFrom = prepareDateFrom(planning.yearMonth);
        const newDateTo = prepareDateTo(planning.yearMonth);
        setDateFrom(newDateFrom);
        setDateTo(newDateTo);
        loadRevenueSummary(planning.budgetBusinessKey, newDateFrom, newDateTo);
        loadPlannedSummary(planning.budgetBusinessKey, newDateFrom, newDateTo);
        const newCompleted = {
            0: planning.revenuesCompleted,
            1: planning.plannedExpensesCompleted
        };
        setCompleted(newCompleted);
    }

    const allStepsCompleted = () => {
        return completed[0] && completed[1];
    };

    const summariesValid = () => {
        return parseFloat(revenueSummary.amount) >= parseFloat(plannedSummary.amount);
    };

    const planningValid = () => {
        return allStepsCompleted() && summariesValid();
    };

    const revenueSummaryValid = () => {
        return revenueSummary.amount !== '0.00';
    };

    useEffect(() => {
        if (planning.yearMonth) {
            init(planning);
        }
    }, [planning]);

    return (
        <div>
            {!loading ? <Box>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key="revenues" completed={completed[0]}>
                        <StepButton
                            color="inherit"
                            onClick={handleStep(0)}
                            optional={<Typography variant="caption">
                                {t('Total amount')}: {revenueSummary.amount} zł.</Typography>}
                        >
                            {t('Revenues')}
                        </StepButton>
                        <StepContent>
                            <RevenuePlanning
                                budgetBusinessKey={planning.budgetBusinessKey}
                                dateFrom={dateFrom}
                                dateTo={dateTo}
                                afterSave={loadRevenueSummary}
                                onClickContinue={handleNext}
                                revenueSummaryValid={revenueSummaryValid()}
                            />
                        </StepContent>
                    </Step>
                    <Step key="planned-expenses" completed={completed[1]}>
                        <StepButton
                            color="inherit"
                            onClick={handleStep(1)}
                            optional={<Typography variant="caption">
                                {t('Total amount')}: {plannedSummary.amount} zł.</Typography>}
                        >
                            {t('Planned expenses')}
                        </StepButton>
                        <StepContent>
                            <PlannedExpenseSuggestions budgetBusinessKey={planning.budgetBusinessKey}
                                                       yearMonth={planning.yearMonth}/>
                            <PlannedExpensePlanning
                                budgetBusinessKey={planning.budgetBusinessKey}
                                dateFrom={prepareDateFrom(planning.yearMonth)}
                                dateTo={prepareDateTo(planning.yearMonth)}
                                expenseCategories={expenseCategories}
                                onSaveExpenseCategory={onSaveExpenseCategory}
                                afterSave={loadPlannedSummary}
                                onClickBack={handleBack}
                                onClickContinue={handleNext}
                                summariesValid={() => summariesValid()}
                            />
                        </StepContent>
                    </Step>
                </Stepper>
                {activeStep === 2 && (
                    <Paper square elevation={0} sx={{p: 3}}>

                        <div>
                            {!summariesValid() ? <Alert severity="error">
                                {t('The total planned expenses exceed the sum of revenues!')}
                            </Alert> : null}
                        </div>
                        <div>
                            <Typography></Typography>
                        </div>
                        <div>
                            <Button
                                sx={{mt: 1, mr: 1}}
                                component={Link}
                                to={"/planning"}
                            >
                                {t('Back')}
                            </Button>
                            {planning.state !== "PLANNED" ? <Button
                                onClick={() => updateAndRedirect(planning.id, "DRAW", planning.revenuesCompleted, planning.plannedExpensesCompleted)}
                                sx={{mt: 1, mr: 1}}
                            >
                                {t('Save draw')}
                            </Button> : null}
                            {planning.state !== "PLANNED" ? <Button
                                disabled={!planningValid()}
                                variant="contained"
                                onClick={() => updateAndRedirect(planning.id, "PLANNED", planning.revenuesCompleted, planning.plannedExpensesCompleted)}
                                sx={{mt: 1, mr: 1}}
                            >
                                {t('Mark as planned')}
                            </Button> : null}
                        </div>
                    </Paper>
                )}
            </Box> : <LoadingItem><CircularProgress/></LoadingItem>}
        </div>
    );
}