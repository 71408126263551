import * as React from 'react';
import {Ref, useRef, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";
import RevenueFormMethods from "../../interfaces/RevenueFormMethods";
import PlannedExpenseForm from "../planned-expense-form/PlannedExpenseForm";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import PlannedExpenseRequestDto from "../../dto/PlannedExpenseRequestDto";
import ExpenseCategoryRequestDto from "../../dto/ExpenseCategoryRequestDto";
import {parseISODate} from "../../utils/date.utils";


interface Props {
    budgetBusinessKey: string,
    expenseCategories: ExpenseCategoryDto[],
    onSave: (request: PlannedExpenseRequestDto) => void,
    onSaveExpenseCategory: (request: ExpenseCategoryRequestDto) => void,
    dateFrom: string
    disabled: boolean
}

export default function PlannedExpenseCreateDialog(props: Props) {
    const {t} = useTranslation();
    const {
        budgetBusinessKey,
        onSave,
        expenseCategories,
        onSaveExpenseCategory,
        dateFrom,
        disabled
    } = props;
    const [open, setOpen] = useState(false);
    const childRef: Ref<RevenueFormMethods> = useRef(null);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOnSave = (data: PlannedExpenseRequestDto) => {
        setOpen(false);
        onSave(data);
    }

    return (
        <div>
            <Button
                onClick={handleClickOpen}
                variant="contained"
                disabled={disabled}
            >
                {t('Create new')}
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t('New planned expense')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>
                    <PlannedExpenseForm
                        ref={childRef}
                        budgetBusinessKey={budgetBusinessKey}
                        expenseCategories={expenseCategories}
                        onSaveExpenseCategory={onSaveExpenseCategory}
                        onSave={handleOnSave}
                        showSaveButton={false}
                        defaultToPayAt={parseISODate(dateFrom)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('Cancel')}</Button>
                    <Button
                        variant="contained"
                        type="submit"
                        onClick={() => childRef.current?.onSubmitVoid()}
                    >{t('Save')}</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};