import {createContext, useEffect, useState} from 'react';
import Cookies from "universal-cookie";
import {axiosInstance} from "../api/axios";
import {
    getBudgetsDropdownItems,
    getConnectionCheck,
    getExpenseCategories,
    getUserWhoami,
    postRefreshToken,
    saveBudget,
    saveExpenseCategory
} from "../api/api";
import BudgetRequestDto from "../dto/BudgetRequestDto";
import BudgetDropdownItemDto from "../dto/BudgetDropdownItemDto";
import {endOfMonth, setHours, setMinutes, setSeconds, startOfMonth} from "date-fns";
import UserDto from "../dto/UserDto";
import {getCookieTime} from "../utils/cookie.utils";
import TokenDto from "../dto/TokenDto";
import {formatDateTime, parseISODateToDiffInSeconds} from "../utils/date.utils";
import ExpenseCategoryDto from "../dto/ExpenseCategoryDto";
import ExpenseCategoryRequestDto from "../dto/ExpenseCategoryRequestDto";

// @ts-ignore
export const AppContext = createContext();
const cookies = new Cookies();


// @ts-ignore
export const AppProvider = ({children}) => {
    const [token, setToken] = useState(cookies.get('token') || '');
    const [refreshToken, setRefreshToken] = useState(cookies.get('refreshToken') || '');
    const [userBusinessKey, setUserBusinessKey] = useState(cookies.get('userBusinessKey') || '');
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [budgets, setBudgets] = useState<BudgetDropdownItemDto[]>([]);
    const [expenseCategories, setExpenseCategories] = useState<ExpenseCategoryDto[]>([]);
    const [selectedBudget, setSelectedBudget] = useState(cookies.get('selectedBudget') as string || '');
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [user, setUser] = useState<UserDto | undefined>();
    const [appLoading, setAppLoading] = useState(true);
    const [isOnline, setIsOnline] = useState(false);

    const prepareDateFrom = () => {
        const currentDate = new Date();
        const firstDayOfMonth = startOfMonth(currentDate);
        const dateTime = setSeconds(setMinutes(setHours(firstDayOfMonth, 0), 0), 0);
        return formatDateTime(dateTime);
    };

    const prepareDateTo = () => {
        const currentDate = new Date();
        const firstDayOfMonth = endOfMonth(currentDate);
        const dateTime = setSeconds(setMinutes(setHours(firstDayOfMonth, 23), 59), 59);
        return formatDateTime(dateTime);
    };

    const loadBudgets = () => {
        setAppLoading(true);
        getBudgetsDropdownItems().then((results) => {
            setBudgets(results);
            if (selectedBudget === '' && results.length > 0) {
                setSelectedBudget(results[0].businessKey);
                getExpenseCategoriesByBudgetBusinessKey(results[0].businessKey);
            }
            setAppLoading(false);
        });
    };

    const loadExpenseCategories = () => {
        getExpenseCategoriesByBudgetBusinessKey(selectedBudget);
    };

    const onSaveExpenseCategory = (data: ExpenseCategoryRequestDto) => {
        saveExpenseCategory(data).then(
            () => {
                loadExpenseCategories();
            }
        );
    };

    const getExpenseCategoriesByBudgetBusinessKey = (budgetBusinessKey: string) => {
        if (budgetBusinessKey === undefined || budgetBusinessKey === '') {
            return;
        }
        setAppLoading(true);
        getExpenseCategories(budgetBusinessKey).then((results) => {
            setExpenseCategories(results);
            setAppLoading(false);
        });
    };

    const checkOnline = () => {
        setAppLoading(true);
        getConnectionCheck().then((result) => {
            setIsOnline(result);
            setAppLoading(false);
        });
    };

    const loadUserData = () => {
        setAppLoading(true);
        getUserWhoami().then(result => {
            if (result === null) {
                logout();
            } else {
                setUser(result);
            }
            setAppLoading(false);
        }).catch(e => {
            logout();
            setAppLoading(false);
        });
    };

    const onSaveBudget = async (data: BudgetRequestDto): Promise<void> => {
        setAppLoading(true);
        await saveBudget(data);
        loadBudgets();
    };

    const onSelectBudget = ($event: any) => {
        const expires = getCookieTime();
        setSelectedBudget($event.target.value);
        cookies.set('selectedBudget', $event.target.value, {expires});
        getExpenseCategoriesByBudgetBusinessKey($event.target.value);
    };
    const checkTokenValidity = () => {
        if (!refreshToken) {
            logout();
            return;
        }

        if (!token || !userBusinessKey) {
            restoreToken(refreshToken).then();
            return;
        }

        const tokenDto = {
            businessKey: userBusinessKey,
            token,
            refreshToken
        } as TokenDto;

        login(tokenDto);
        loadBudgets();
    };

    useEffect(() => {
        checkOnline();
        checkTokenValidity();
    }, [token]);

    useEffect(() => {
        if (isLoggedIn) {
            loadBudgets();
            loadUserData();
            setDateFrom(prepareDateFrom());
            setDateTo(prepareDateTo());
        }
    }, [isLoggedIn]);

    const setAxiosHeader = (token: string) => {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    };

    const restoreToken = async (refreshToken: string) => {
        setAppLoading(true);
        return postRefreshToken(refreshToken).then((tokenDto: TokenDto) => {
            login(tokenDto);
            loadBudgets();
            setAppLoading(false);
        }, () => {
            logout();
            setAppLoading(false);
        });
    };

    const login = (tokenDto: TokenDto) => {
        setToken(tokenDto.token);
        setRefreshToken(tokenDto.refreshToken);
        setUserBusinessKey(tokenDto.businessKey);
        if (tokenDto.tokenExpiresAt) {
            setAuthCookies(tokenDto);
        }
        setAxiosHeader(tokenDto.token);
        setIsLoggedIn(true);
    };

    const setAuthCookies = (tokenDto: TokenDto) => {
        const expiresToken = parseISODateToDiffInSeconds(tokenDto.tokenExpiresAt);
        const expiresRefreshToken = parseISODateToDiffInSeconds(tokenDto.refreshTokenExpiresAt);
        cookies.set('token', tokenDto.token, {maxAge: expiresToken});
        cookies.set('userBusinessKey', tokenDto.businessKey, {maxAge: expiresToken});
        cookies.set('refreshToken', tokenDto.refreshToken, {maxAge: expiresRefreshToken});
    };

    const logout = () => {
        setIsLoggedIn(false);
        setToken('');
        setRefreshToken('');
        cookies.remove('token');
        cookies.remove('refreshToken');
        cookies.remove('userBusinessKey');
    };

    const onSetDateFrom = (dateFrom: string) => {
        setDateFrom(dateFrom);
    };

    const onSetDateTo = (dateTo: string) => {
        setDateTo(dateTo);
    };

    const onSetAppLoading = (state: boolean) => {
        setAppLoading(state);
    };

    const contextValue: {
        loadBudgets: () => void;
        onSetDateTo: (dateTo: string) => void;
        expenseCategories: ExpenseCategoryDto[];
        onSaveExpenseCategory: (request: ExpenseCategoryRequestDto) => void
        onSaveBudget: (data: BudgetRequestDto) => Promise<void>;
        appLoading: boolean;
        isOnline: boolean;
        dateFrom: string;
        dateTo: string;
        onSelectBudget: ($event: any) => void;
        login: (tokenDto: TokenDto) => void;
        selectedBudget: string;
        onSetDateFrom: (dateFrom: string) => void;
        token: unknown;
        logout: () => void;
        userBusinessKey: unknown;
        budgets: BudgetDropdownItemDto[];
        isLoggedIn: boolean;
        loadExpenseCategories: () => void;
        onSetAppLoading: (state: boolean) => void;
        user: UserDto | undefined
    } = {
        token,
        userBusinessKey,
        isLoggedIn,
        budgets,
        expenseCategories,
        onSaveExpenseCategory,
        selectedBudget,
        dateFrom,
        dateTo,
        user,
        appLoading,
        isOnline,
        loadBudgets,
        loadExpenseCategories,
        onSelectBudget,
        onSaveBudget,
        login,
        logout,
        onSetDateFrom,
        onSetDateTo,
        onSetAppLoading
    };

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
};
