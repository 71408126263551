import * as React from 'react';
import {Autocomplete, FormControl, TextField} from "@mui/material";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import {useTranslation} from "react-i18next";

interface Props {
    expenseCategories: ExpenseCategoryDto[],
    onSelect: any
}

function ExpenseCategoryAutocomplete(props: Props) {
    const {t} = useTranslation();
    const {expenseCategories, onSelect} = props;
    const isReady = () => {
        return expenseCategories.length > 0
            || expenseCategories.length === 0;
    };

    return (
        <div>
            {isReady() ? <div>
                <FormControl fullWidth>
                    <Autocomplete
                        id="category-autocomplete"
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => {
                            return option.businessKey === value.businessKey
                        }}
                        options={expenseCategories}
                        onChange={onSelect}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={t('Category')}
                            />}
                    />
                </FormControl>
            </div> : <div>{t('No expense categories')}</div>}
        </div>
    );
}

export default ExpenseCategoryAutocomplete;