import * as React from 'react';
import {useEffect, useState} from 'react';
import {useForm} from "react-hook-form";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Stack,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    TextField
} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import RealExpenseRequestDto from "../../dto/RealExpenseRequestDto";
import PlannedExpenseDropdownDto from "../../dto/PlannedExpenseDropdownDto";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import ExpenseCategoryForm from "../expense-category-form/ExpenseCategoryForm";
import {DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {pl} from "date-fns/locale";
import ExpenseCategoryAutocomplete from "../expense-category-autocomplete/ExpenseCategoryAutocomplete";
import ExpenseDescriptionAutocomplete from "../expense-description-autocomplete/ExpenseDescriptionAutocomplete";
import IconButton from "@mui/material/IconButton";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import {getBalanceTextStyle} from "../../utils/balance.utils";
import PlannedExpenseAutocomplete from "../planned-expense-autocomplete/PlannedExpenseAutocomplete";
import Typography from "@mui/material/Typography";
import {formatDateTime, formatDateTimeToString} from "../../utils/date.utils";
import {useTranslation} from "react-i18next";
import ExpenseCategoryRequestDto from "../../dto/ExpenseCategoryRequestDto";
import FormItem from "../form-item/FormItem";
import Cookies from "universal-cookie";
import {getCookieTime} from "../../utils/cookie.utils";


interface Props {
    budgetBusinessKey: string,
    categories: ExpenseCategoryDto[],
    plannedExpenses: PlannedExpenseDropdownDto[],
    initialPlannedExpense: PlannedExpenseDropdownDto | null,
    onSaveRealExpense: any,
    onSaveExpenseCategory: (request: ExpenseCategoryRequestDto) => void,
    loadPlannedExpenses: any
}

function RealExpenseForm(props: Props) {
    const {t} = useTranslation();
    const cookies = new Cookies();
    const {
        budgetBusinessKey,
        categories,
        plannedExpenses,
        onSaveRealExpense,
        onSaveExpenseCategory,
        initialPlannedExpense,
        loadPlannedExpenses
    } = props;
    const {register, handleSubmit, setValue, getValues, reset, trigger, formState: {errors}} = useForm();
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState(cookies.get('rExpAmount'));
    const [plannedPaid, setPlannedPaid] = useState(false);
    const defaultPaidAt = new Date();
    const [paidAt, setPaidAt] = useState(defaultPaidAt);
    const [selectedCategoryName, setSelectedCategoryName] = useState<string>('');
    const [selectedPlannedExpense, setSelectedPlannedExpense] = useState<PlannedExpenseDropdownDto | null>(initialPlannedExpense);
    const [isCategoriesVisible, setIsCategoriesVisible] = useState(true);
    const [activeStep, setActiveStep] = React.useState(0);

    const isPlannedPaidVisible = () => {
        return selectedPlannedExpense !== null;
    };

    const loadPlannedExpensesIfEmpty = (stepNo: number) => {
        if (stepNo === 2 && plannedExpenses.length === 0) {
            loadPlannedExpenses();
        }
    };

    const handleNext = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        loadPlannedExpensesIfEmpty(activeStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        resetForm();
        setActiveStep(0);
        loadPlannedExpenses();
    };

    const onSubmit = async (data: any) => {
        const isValid = await trigger();
        if (!isValid) {
            return;
        }
        const realExpenseData = data as RealExpenseRequestDto;
        onSaveRealExpense(realExpenseData);
        resetForm();
    };

    const onSelectCategory = (event: any, value: ExpenseCategoryDto | null) => {
        setValue('categoryBusinessKey', value?.businessKey);
        setSelectedCategoryName(value !== null ? value.name : '');
    };

    const onSelectDescription = (event: any, value: string) => {
        setDescription(value);
        setValue('description', value);
    };

    const onSelectPlannedExpense = (event: any, value: any) => {
        setSelectedPlannedExpense(value);

        if (value !== null) {
            setSelectedCategoryName(value.category.name);
            setValue('plannedExpenseBusinessKey', value?.businessKey);
            setDescription(value?.description);
            setValue('description', value?.description);
            setValue('categoryBusinessKey', value?.category.businessKey);
            if (amount != '') {
                setActiveStep(activeStep + 1);
            }
        } else {
            reset();
            setSelectedCategoryName('');
        }

        setIsCategoriesVisible(value === null);
    };

    //todo: DRY maybe should be refactored?
    const handleDateFieldChange = (fieldName: string, date: any) => {
        setValue(fieldName, formatDateTime(date), {shouldValidate: true});
        setPaidAt(date);
    };

    const onChangeAmount = (event: any) => {
        addAmount(event.target.value);
    }

    const addAmount = (value: string) => {
        const parsed = value.replace(",", ".")
        setAmount(parsed);
        setValue("amount", parsed);
        onChangePlannedPaid(true)
        storeAmountCookie(parsed);
    };

    const onChangePlannedPaidEvent = (event: any) => {
        onChangePlannedPaid(event.target.checked);
    };

    const onChangePlannedPaid = (state: boolean) => {
        setValue("plannedPaid", state);
        setPlannedPaid(state);
    };

    const storeAmountCookie = (value: string) => {
        const expires = getCookieTime();
        cookies.set('rExpAmount', value, {expires});
    };

    const clearAmountCookie = () => {
        cookies.remove('rExpAmount');
    };

    const resetForm = () => {
        reset();
        onSelectPlannedExpense(null, initialPlannedExpense);
        setSelectedCategoryName('');
        setIsCategoriesVisible(true);
        setDescription('');
        setAmount('');
        clearAmountCookie();
        handleDateFieldChange('paidAt', defaultPaidAt);
    };

    useEffect(() => {
        handleDateFieldChange('paidAt', defaultPaidAt);
    }, []);

    useEffect(() => {
        onSelectPlannedExpense(null, initialPlannedExpense);
        handleDateFieldChange('paidAt', defaultPaidAt);
    }, [initialPlannedExpense]);

    useEffect(() => {
        setValue("budgetBusinessKey", budgetBusinessKey);
    }, [budgetBusinessKey]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step key={"Amount"}>
                        <StepLabel optional={(
                            amount ? <Typography variant="caption">{amount} zł.</Typography> : null)}>
                            {t('Amount')}
                        </StepLabel>
                        <StepContent>
                            <Typography>{t('Type amount value.')}</Typography>
                            <Box sx={{mb: 2}}>
                                <FormControl fullWidth>
                                    <InputLabel htmlFor="adornment-amount">
                                        {t('Amount')}
                                    </InputLabel>
                                    <OutlinedInput
                                        fullWidth
                                        type="string"
                                        inputProps={{
                                            inputMode: "decimal",
                                            pattern: "[0-9.,]*",
                                            autoComplete: "off"
                                        }}
                                        value={amount}
                                        {...register("amount", {required: true})}
                                        id="adornment-amount"
                                        endAdornment={<InputAdornment position="end">zł.</InputAdornment>}
                                        onChange={onChangeAmount}
                                    />
                                    {isPlannedPaidVisible() ? <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={plannedPaid}
                                                value={plannedPaid}
                                                onChange={onChangePlannedPaidEvent}
                                                color="primary"
                                            />
                                        }
                                        label={t("Is planned paid?")}
                                    /> : null}
                                    {errors.amount && <FormHelperText error>{t('Amount is required')}</FormHelperText>}
                                    {selectedPlannedExpense ?
                                        <FormHelperText style={getBalanceTextStyle(selectedPlannedExpense.balance)}>
                                            {t('Planned expense balance')}: {selectedPlannedExpense.balance} zł.
                                            {parseFloat(selectedPlannedExpense.balance) > 0.0
                                                ? <IconButton color="primary"
                                                              aria-label="Set amount"
                                                              onClick={() => addAmount(selectedPlannedExpense.balance)}>
                                                    <AddShoppingCartIcon/>
                                                </IconButton>
                                                : null}
                                        </FormHelperText>
                                        : null
                                    }
                                </FormControl>
                            </Box>
                            <Box sx={{mb: 2, display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    variant="contained"
                                    disabled={!amount}
                                    onClick={handleNext}
                                    sx={{mt: 1, mr: 1}}>
                                    {t('Continue')}
                                </Button>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key={"Paid at"}>
                        <StepLabel optional={(
                            paidAt ?
                                <Typography variant="caption">{formatDateTimeToString(paidAt)}</Typography> : null)}>
                            {t('Paid at')}
                        </StepLabel>
                        <StepContent>
                            <Typography>{t('Select paid date.')}</Typography>
                            <Box sx={{mb: 2}}>
                                <Stack spacing={2}>
                                    <FormItem>
                                        <LocalizationProvider adapterLocale={pl} dateAdapter={AdapterDateFns}>
                                            <DateTimePicker
                                                label={t('Paid at')}
                                                value={paidAt}
                                                onChange={(newValue) => {
                                                    handleDateFieldChange('paidAt', newValue);
                                                }}
                                            />
                                        </LocalizationProvider>
                                        <input type="hidden" {...register("paidAt", {required: true})} />
                                        {errors.paidAt &&
                                            <FormHelperText error>{t('Paid At is required')}</FormHelperText>}
                                    </FormItem>
                                </Stack>
                            </Box>
                            <Box sx={{mb: 2, display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    disabled={false}
                                    onClick={handleBack}
                                    sx={{mt: 1, mr: 1}}>
                                    {t('Back')}
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleNext}
                                    sx={{mt: 1, mr: 1}}
                                >
                                    {t('Continue')}
                                </Button>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key={"Planned expense / Category"}>
                        <StepLabel optional={(
                            selectedPlannedExpense ? <Typography
                                variant="caption">{selectedPlannedExpense.description}</Typography> : null)}>
                            {t('Planned expense')} / {t('Category')}
                        </StepLabel>
                        <StepContent>
                            <Typography>{t('Select planned expense or category.')}</Typography>
                            <Box sx={{mb: 2}}>
                                <Stack spacing={2}>
                                    <FormItem>
                                        <PlannedExpenseAutocomplete
                                            selectedPlannedExpense={selectedPlannedExpense}
                                            plannedExpenses={plannedExpenses}
                                            onSelect={onSelectPlannedExpense}
                                        />
                                        <input
                                            type="hidden" {...register("plannedExpenseBusinessKey", {required: false})} />
                                    </FormItem>
                                    {isCategoriesVisible ? <FormItem>
                                        <ExpenseCategoryAutocomplete
                                            expenseCategories={categories}
                                            onSelect={onSelectCategory}
                                        />
                                        <input type="hidden" {...register("categoryBusinessKey", {required: true})} />
                                        {errors.categoryBusinessKey &&
                                            <FormHelperText>{t('Category is required')}</FormHelperText>}
                                    </FormItem> : <FormItem>
                                        <TextField
                                            fullWidth
                                            disabled
                                            id="category-disabled"
                                            label={t('Category (from planned expense)')}
                                            value={selectedCategoryName}
                                        />
                                    </FormItem>}
                                    {isCategoriesVisible ? <FormItem>
                                        <ExpenseCategoryForm budgetBusinessKey={budgetBusinessKey}
                                                             onSave={onSaveExpenseCategory}/>
                                    </FormItem> : null}
                                </Stack>
                            </Box>
                            <Box sx={{mb: 2, display: 'flex', justifyContent: 'flex-end'}}>
                                <Button
                                    disabled={false}
                                    onClick={handleBack}
                                    sx={{mt: 1, mr: 1}}
                                >
                                    {t('Back')}
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={(selectedCategoryName.length === 0 && selectedPlannedExpense === null)}
                                    onClick={handleNext}
                                    sx={{mt: 1, mr: 1}}
                                >
                                    {t('Continue')}
                                </Button>
                            </Box>
                        </StepContent>
                    </Step>
                    <Step key={"Description"}>
                        <StepLabel optional={(<Typography variant="caption">{t('Last step')}</Typography>)}>
                            {t('Description')}
                        </StepLabel>
                        <StepContent>
                            <Typography>{t('Type expense description.')}</Typography>
                            <Box sx={{mb: 2}}>
                                <Stack spacing={2}>
                                    <FormItem>
                                        <ExpenseDescriptionAutocomplete value={description}
                                                                        onSelect={onSelectDescription}/>
                                        <input type="hidden" {...register("description", {required: true})} />
                                        {errors.description &&
                                            <FormHelperText>{t('Description is required')}</FormHelperText>}
                                    </FormItem>
                                </Stack>
                            </Box>
                        </StepContent>
                    </Step>
                </Stepper>
                {activeStep === 3 && (
                    <Paper square elevation={0} sx={{p: 3}}>
                        <Typography>{t('Summary')}:</Typography>
                        <Typography>{t('Amount')}: {amount} zł.</Typography>
                        {paidAt ? <Typography>{t('Paid at')}: {formatDateTimeToString(paidAt)}</Typography> : null}
                        {selectedPlannedExpense ?
                            <Typography>
                                {t('Planned expense')}: {selectedPlannedExpense.description}
                            </Typography> : null
                        }
                        {selectedCategoryName
                            ? <Typography>{t('Category')}: {selectedCategoryName}</Typography> : null}
                        <Typography>{t('Description')}: {description}</Typography>
                    </Paper>
                )}
            </Box>
            {activeStep === 3 ? <Box sx={{mb: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        disabled={false}
                        onClick={handleBack}
                        sx={{mt: 1, mr: 1}}
                    >
                        {t('Back')}
                    </Button>
                    <Button onClick={handleReset} sx={{mt: 1, mr: 1}}>
                        {t('Reset')}
                    </Button>
                    <Button
                        disabled={!description || description.length === 0}
                        variant="contained"
                        type="submit"
                        onClick={() => onSubmit(getValues())}
                        sx={{mt: 1, mr: 1}}
                    >
                        {t('Save')}
                    </Button>
                </Box>
                : null}
        </form>
    );
}

export default RealExpenseForm;