import * as React from 'react';
import {forwardRef, Ref, useEffect, useImperativeHandle} from 'react';
import {useForm} from "react-hook-form";
import {FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, Stack, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {useTranslation} from "react-i18next";
import RevenueRequestDto from "../../dto/RevenueRequestDto";
import RevenueFormMethods from "../../interfaces/RevenueFormMethods";
import {formatDateTime} from "../../utils/date.utils";
import FormItem from "../form-item/FormItem";


interface Props {
    budgetBusinessKey: string,
    onSave: (request: RevenueRequestDto) => void,
    defaultReceiptAt: Date | undefined
    showSaveButton: boolean
}

const RevenueForm = forwardRef((props: Props, ref: Ref<RevenueFormMethods>) => {
    const {
        budgetBusinessKey,
        onSave,
        defaultReceiptAt,
        showSaveButton
    } = props;
    const {t} = useTranslation();
    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        formState: {errors}
    } = useForm();

    const onSubmitVoid = () => {
        const data = getValues() as RevenueRequestDto;
        onSave(data);
    };

    const onSubmit = (data: any): void => {
        const request = data as RevenueRequestDto;
        onSave(request);
    };

    const handleDateFieldChange = (fieldName: string, date: any) => {
        setValue(fieldName, formatDateTime(date), {shouldValidate: true});
    };

    const onChangeAmount = (event: any) => {
        setValue("amount", event.target.value.replace(",", "."));
    };

    useImperativeHandle(ref, () => ({
        onSubmitVoid,
    }));

    useEffect(() => {
        setValue("budgetBusinessKey", budgetBusinessKey);
        if (defaultReceiptAt) {
            handleDateFieldChange("receiptAt", defaultReceiptAt);
        }
    }, [budgetBusinessKey]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{width: '100%'}}>
                <Stack spacing={2}>
                    <FormItem>
                        <TextField
                            fullWidth
                            defaultValue="" {...register("name", {required: true})}
                            label={t('Name')}
                            helperText={errors.name && t('Name is required')}
                            multiline
                        />
                    </FormItem>
                    <FormItem>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="adornment-amount">
                                {t('Amount')}
                            </InputLabel>
                            <OutlinedInput
                                fullWidth
                                type="string"
                                inputProps={{
                                    inputMode: "decimal",
                                    pattern: "[0-9.,]*",
                                    autoComplete: "off"
                                }}
                                defaultValue=""
                                {...register("amount", {required: true})}
                                onChange={onChangeAmount}
                                id="adornment-amount"
                                endAdornment={<InputAdornment position="end">zł.</InputAdornment>}
                            />
                            {errors.amount && <FormHelperText>{t('Amount is required')}</FormHelperText>}
                        </FormControl>
                    </FormItem>
                    <FormItem>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={t('Receipt at')}
                                defaultValue={defaultReceiptAt}
                                onChange={(newValue) => handleDateFieldChange('receiptAt', newValue)}
                            />
                        </LocalizationProvider>
                    </FormItem>
                    {showSaveButton ? <FormItem>
                        <Button variant="contained" type="submit">{t('Save')}</Button>
                    </FormItem> : null}
                </Stack>
            </Box>
        </form>
    );
});

export default RevenueForm;