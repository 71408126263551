import ExpenseCategoryDto from "../dto/ExpenseCategoryDto";

export const getCategoryByBusinessKey = (categories: ExpenseCategoryDto[], businessKey?: string) => {
    if (businessKey === undefined) {
        return null;
    }
    const category = categories.find(cat => cat.businessKey === businessKey);

    if (category === undefined) {
        return null;
    }

    return category;
}

