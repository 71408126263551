import * as React from 'react';
import {useState} from 'react';
import {useTranslation} from "react-i18next";
import {Alert, CircularProgress} from "@mui/material";
import {LoadingItem} from "../loading-item/LoadingItem";
import PlannedExpenseWidget from "../planned-expense-widget/PlannedExpenseWidget";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";
import {savePlannedExpense} from "../../api/api";
import PlannedExpenseRequestDto from "../../dto/PlannedExpenseRequestDto";
import PlannedExpenseCreateDialog from "../planned-expense-create-dialog/PlannedExpenseCreateDialog";
import ExpenseCategoryRequestDto from "../../dto/ExpenseCategoryRequestDto";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

interface Props {
    budgetBusinessKey: string,
    dateFrom: string,
    dateTo: string
    expenseCategories: ExpenseCategoryDto[]
    onSaveExpenseCategory: (request: ExpenseCategoryRequestDto) => void
    afterSave: (budgetBusinessKey: string, dateFrom: string, dateTo: string) => void
    onClickContinue: () => void
    onClickBack: () => void
    summariesValid: () => boolean
}

export default function PlannedExpensePlanning(props: Props) {
    const {t} = useTranslation();
    const {
        budgetBusinessKey,
        dateFrom,
        dateTo,
        expenseCategories,
        onSaveExpenseCategory,
        afterSave,
        onClickBack,
        onClickContinue,
        summariesValid
    } = props;
    const [loading, setLoading] = useState(false);

    const onSave = (data: PlannedExpenseRequestDto) => {
        setLoading(true);
        savePlannedExpense(data).then(() => {
            setLoading(false);
            afterSave(budgetBusinessKey, dateFrom, dateTo);
        });
    };

    return (
        <div>
            {!loading ?
                <div>
                    <div>
                        <PlannedExpenseCreateDialog
                            budgetBusinessKey={budgetBusinessKey}
                            expenseCategories={expenseCategories}
                            onSave={onSave}
                            onSaveExpenseCategory={onSaveExpenseCategory}
                            dateFrom={dateFrom}
                            disabled={!summariesValid()}
                        />
                    </div>
                    <div>
                        {!summariesValid() ? <Alert severity="error">
                            {t('The total planned expenses exceed the sum of revenues!')}
                        </Alert> : null}
                    </div>
                    <div>
                        <Box sx={{mb: 2}}>
                            <div>
                                <Button
                                    disabled={false}
                                    onClick={onClickBack}
                                    sx={{mt: 1, mr: 1}}
                                >
                                    {t('Back')}
                                </Button>
                                <Button
                                    variant="contained"
                                    disabled={!summariesValid()}
                                    onClick={onClickContinue}
                                    sx={{mt: 1, mr: 1}}
                                >
                                    {t('Complete')}
                                </Button>
                            </div>
                        </Box>
                    </div>
                    <div>
                        <h2>{t('Planned expenses')}</h2>
                        <PlannedExpenseWidget
                            budgetBusinessKey={budgetBusinessKey}
                            dateFrom={dateFrom}
                            dateTo={dateTo}
                            expenseCategories={expenseCategories}
                            afterChange={() => afterSave(budgetBusinessKey, dateFrom, dateTo)}
                        />
                    </div>
                </div>
                : <LoadingItem><CircularProgress/></LoadingItem>}
        </div>
    );
}