import {getCategoryByBusinessKey} from "../../utils/expenseCategory.utils";
import RealExpenseSummaryDto from "../../dto/RealExpenseSummaryDto";
import {pieArcLabelClasses, PieChart} from "@mui/x-charts/PieChart";
import * as React from "react";
import ExpenseCategoryDto from "../../dto/ExpenseCategoryDto";

interface Props {
    summary: RealExpenseSummaryDto
    categories: ExpenseCategoryDto[]
}

export function RealExpensesPieChart(props: Props) {
    const {summary, categories} = props;
    const mapSummaryToChartData = () => {
        if (summary.balanceByCategoryAmount) {
            return Object.entries(summary.balanceByCategoryAmount).map(
                ([categoryBusinessKey, amount]) => {
                    const category = getCategoryByBusinessKey(categories, categoryBusinessKey)
                    return {id: categoryBusinessKey, value: amount, formattedValue: `${amount} zł.`, label: category?.name}
                }
            )
        }
        return [];
    };

    return (
        <div>
            <PieChart
                series={[
                    {
                        arcLabel: (item) => `-${item.value} zł.`,
                        data: mapSummaryToChartData(),
                        cx: 170
                    },
                ]}
                legend={{
                    hidden: true
                }}
                height={250}
                sx={{
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white'
                    },
                }}
            />
        </div>
    );
}