import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {useTranslation} from "react-i18next";
import PlanningDto from "../../dto/PlanningDto";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import TaskAltRoundedIcon from '@mui/icons-material/TaskAltRounded';
import Tooltip from "@mui/material/Tooltip";
import {useTheme} from "@mui/material";

interface Props {
    row: PlanningDto
    isSelected: boolean
}

export default function PlanningListRow(props: Props) {
    const {t} = useTranslation();
    const theme = useTheme();
    const {row, isSelected} = props;
    const navigate = useNavigate();

    const edit = (id: string) => {
        navigate(`/planning/${id}`);
    };

    return (
        <TableRow
            sx={{backgroundColor: isSelected ? theme.palette.action.hover : 'inherit', cursor: 'pointer'}}
        >
            <TableCell style={{width: 50}} align="left">
                {row.yearMonth}
                {row.state === "PLANNED"
                    ? <Tooltip title="Planned">
                        <TaskAltRoundedIcon sx={{fontSize: 20, verticalAlign: 'middle'}}/>
                    </Tooltip>
                    : null
                }
            </TableCell>
            <TableCell style={{width: 100}} align="left">
                <Button
                    onClick={() => edit(row.id)}
                    variant="outlined"
                >{t('Edit')}</Button>
            </TableCell>
        </TableRow>
    );
}