import * as React from 'react';
import {Autocomplete, FormControl, FormHelperText, TextField} from "@mui/material";
import PlannedExpenseDropdownDto from "../../dto/PlannedExpenseDropdownDto";
import {useTranslation} from "react-i18next";

interface Props {
    selectedPlannedExpense: PlannedExpenseDropdownDto | null;
    plannedExpenses: PlannedExpenseDropdownDto[];
    onSelect: any;
}

export default function PlannedExpenseAutocomplete(props: Props) {
    const {t} = useTranslation();
    const {selectedPlannedExpense, plannedExpenses, onSelect} = props;

    const isReadyPlannedExpenseAutocomplete = () => {
        return plannedExpenses.length > 0
            || plannedExpenses.length === 0;
    };

    return (
        <div>
            {isReadyPlannedExpenseAutocomplete() ? <div>
                <FormControl fullWidth>
                    <Autocomplete
                        id="planned-expense-autocomplete"
                        getOptionLabel={(option) => `${option.description}`}
                        isOptionEqualToValue={(option, value) => {
                            return option.businessKey === value.businessKey
                        }}
                        options={plannedExpenses}
                        onChange={onSelect}
                        value={selectedPlannedExpense || null}
                        ListboxProps={{style: {maxHeight: 200}}}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label={t('Planned expense')}
                            />}
                    />
                </FormControl>
            </div> : <div>{t('No planned expenses')}</div>}
            {selectedPlannedExpense ?
                <FormHelperText>
                    {t('Amount')}: {selectedPlannedExpense?.amount} zł.<br/>
                    {t('To pay at')}: {selectedPlannedExpense?.toPayAt}
                </FormHelperText>
                : null
            }
        </div>
    );
};